/* @jsx jsx */

import React, { useState, useEffect, useRef } from "react"
import { css, jsx } from "@emotion/react"
import { desktop, mobile } from "@src/style"

import koetspng from "../../assets/images/koetswit.png"

const loadingMsg = [
  "Koets wordt binnengereden...",
  "Paarden worden gevoerd...",
  "Koetsierszitje wordt getest...",
  "Fluweel wordt afgestoft...",
  "Lampen worden aangestoken...",
  "Goud wordt opgepoetst...",
]

const loadingMsgEn = [
  "Coach is driven in...",
  "Horses are being fed...",
  "Coachman's seat is being tested...",
  "Velvet is being dusted...",
  "Lamps are being lit...",
  "Gold is being polished...",
]

export function LoadingScreen({ locale }) {
  let msg = locale == "nl" ? [...loadingMsg] : [...loadingMsgEn]

  const [msgIndex, setMsgIndex] = useState(0)

  const msgCounter = useRef(0)

  useEffect(() => {
    setTimeout(increaseMsg, 2500)
  }, [])

  const increaseMsg = () => {
    msgCounter.current++

    if (msgCounter.current < msg.length) {
      setMsgIndex(prev => prev + 1)
      setTimeout(increaseMsg, 3500)
    }
  }

  return (
    <div
      css={css`
        font-family: "GT Flexa Trial", "Helvetica", "Arial";
        text-align: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      `}
    >
      <div
        css={css`
         margin: auto;
          margin-top: 40vh;
          ${mobile}{
            margin-top: 30vh;
            padding: 0 30px;
          }
          @keyframes fade {
            0% {opacity: 0.5}
            100%{opacity: 1}
          }

        `}
      >
        <div
          css={css`
            font-size: 1.8rem;
            padding: 0px 30px 0px 30px;
            ${desktop} {
              font-size: 3rem;
            }
            animation: fade 1s infinite;
            animation-direction: alternate;
            font-weight: normal;
          `}
        >
          {msg[msgIndex]}
        </div>
        <div
          css={css`
            margin-top: 20px;
            font-size: 1rem;
            ${desktop} {
              font-size: 1.3rem;
            }
            text-align: center;
            width: 100%;
            animation: fade 1s infinite;
            animation-direction: alternate;
            font-family: "Everett-Light";
          `}
        >
          {locale == "nl"
            ? "(Scroll naar beneden voor meer info over de Gouden Koets)"
            : "(Scroll down for more information about the Golden Coach)"}
        </div>
        <img
          css={css`
            margin: auto;
            margin-top: 30px;
            width: auto;
            height: auto;
            opacity: 0;
            transform: translateX(150px) scale(1, 1);

            @keyframes koetsbeweegt {
              0% {
                opacity: 0;
                transform: translateX(150px) scale(1, 1);
              }
              5% {
                opacity: 1;
              }
              35% {
                opacity: 1;
              }
              40% {
                opacity: 0;
                transform: translateX(-150px) scale(1, 1);
              }
              50% {
                opacity: 0;
                transform: translateX(-150px) scale(-1, 1);
              }
              55% {
                opacity: 1;
              }
              85% {
                opacity: 1;
              }
              90% {
                opacity: 0;
                transform: translateX(150px) scale(-1, 1);
              }
            }

            animation: koetsbeweegt 6s infinite;
            animation-timing-function: linear;
          `}
          src={koetspng}
        />
      </div>
    </div>
  )
}
