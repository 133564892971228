/** @jsx jsx */

import { css, jsx } from "@emotion/react"
import { Body, Column, H1, H4, Row, Section } from "@src/components"
import { colors, desktop } from "@src/style"

export function moduleIntro(
  title: any,
  bodyNode: any,
  background: Color,
  type = "mening"
) {
  return (
    <Section
      background={background}
      noReveal
      css={css`
        padding-top: 48px;
        padding-bottom: 1px;
        ${desktop} {
          padding-top: 48px;
          padding-bottom: 20px;
        }
      `}
    >
      <Row>
        <Column>
          <H4
            css={css`
              color: ${background === "red" ? colors.black : colors.red};
            `}
          >
            {type}
          </H4>
        </Column>
      </Row>
      <Row>
        <Column
          css={css`
            ${desktop} {
              flex: 6;
            }
          `}
        >
          <H1>{title}</H1>
        </Column>
        <Column
          css={css`
            ${desktop} {
              flex: 1;
            }
          `}
        ></Column>
        <Column
          css={css`
            ${desktop} {
              flex: 4;
            }
          `}
        >
          <Body
            dangerouslySetInnerHTML={{
              __html: bodyNode.childMarkdownRemark.html,
            }}
          />
        </Column>
      </Row>
    </Section>
  )
}
