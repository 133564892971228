/* @jsx jsx */

import React from "react"
import { css, jsx } from "@emotion/react"

export function ZoomScreenDesktop({
  title,
  text,
  visible,
  zoomInAnimation,
  currentZoomPoint,
  amountOfPoints,
  youtubeLink,
  leesMeerLink,
  setUserZoom,
  setUserClicked,
  backToMain,
  userZoom,
  width,
  fluid,
  locale,
}) {
  let nextZoomPoint = currentZoomPoint + 1
  if (nextZoomPoint > amountOfPoints - 1) {
    nextZoomPoint = 0
  }
  let prevZoomPoint = currentZoomPoint - 1
  if (prevZoomPoint < 0) {
    prevZoomPoint = amountOfPoints - 1
  }
  return (
    <div
      className={visible ? "show" : "hide"}
      css={css`
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
      `}
      style={userZoom ? { pointerEvents: "auto" } : { pointerEvents: "none" }}
    >
      <div
        css={css`
          height: 60%;
          width: 350px;
          right: 10%;
          padding-right: 30px;
          top: 20%;
          font-family: "GT Flexa Trial";
          font-size: 1rem;
          position: absolute;
          overflow-y: auto;
          z-index: 1;
          transition: opacity 1s;
        `}
      >
        {/* PAGE COUNTER */}
        <div
          css={css`
            font-family: "Everett-Light";
            font-size: 1rem;
            font-weight: lighter;
          `}
        >
          {currentZoomPoint + 1}/{amountOfPoints}
        </div>

        {/* CONTENT */}
        <h1
          css={css`
            margin-top: 0;
            font-size: 3rem;
            letter-spacing: 0rem;
            text-transform: uppercase;
            font-weight: normal;
          `}
        >
          {title}
        </h1>
        {youtubeLink && (
          <iframe
            width="100%"
            height="168,75px"
            src={youtubeLink + "?autoplay=1&mute=1"}
            css={css`
              border: 0;
            `}
          ></iframe>
        )}
        <p
          css={css`
            font-family: "Everett-Light";
            font-weight: 200;
            line-height: 1.4rem;
            letter-spacing: 0.05rem;
          `}
          dangerouslySetInnerHTML={{
            __html: text.childMarkdownRemark!.html!,
          }}
        ></p>
        {leesMeerLink && (
          <a
            css={css`
          font-family: "Everett-Light";
          font-weight: lighter;
          background-color: #1e3e47;
          height: 41px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          width: auto;
          line-height: 41px;
          text-decoration: none;
          display: inline-block;
          padding: 0 30px 0 30px;
          &:hover{
            background-color: white;
            color: #1e3e47;
            border 1px solid #1e3e47;
          }
        `}
            href={leesMeerLink}
          >
            {locale == "nl" ? "Verder lezen →" : "Read more →"}
          </a>
        )}
      </div>

      {/* TERUG NAAR DE KOETS + VOLGENDE + VORIGE KNOP */}
      <div
        css={css`
          width: 80%;
          position: absolute;
          bottom: 60px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: space-between;
          z-index: 1;
          text-transform: uppercase;
          font-family: "GT Flexa Trial";
          font-size: 1.5rem;
        `}
      >
        {/* TERUG NAAR KOETS */}
        <div
          css={css`
            display: flex;
            cursor: pointer;
            &:hover {
              font-style: italic;
            }
          `}
          onClick={() => {
            setUserZoom(false)
            backToMain()
            setTimeout(() => setUserClicked(false), 100)
          }}
        >
          <div
            css={css`
              background-image: url("/svg/arrowleftwhitedouble.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              height: 1.5rem;
              width: 1.5rem;
              margin-right: 5px;
            `}
          ></div>
          {locale == "nl" ? "terug naar de koets" : "back to the coach"}
        </div>
        {/* VORIGE */}
        <div
          css={css`
            display: flex;
            cursor: pointer;
            &:hover {
              font-style: italic;
            }
          `}
          onClick={() => zoomInAnimation(prevZoomPoint)}
        >
          {" "}
          <div
            css={css`
              background-image: url("/svg/arrowleftwhite.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              height: 1.5rem;
              width: 1.5rem;
              margin-right: 5px;
            `}
          ></div>
          {locale == "nl" ? "VORIGE" : "PREVIOUS"}
        </div>

        {/* VOLGENDE */}
        <div
          css={css`
            display: flex;
            cursor: pointer;
            &:hover {
              font-style: italic;
            }
          `}
          onClick={() => zoomInAnimation(nextZoomPoint)}
        >
          {locale == "nl" ? "VOLGENDE" : "NEXT"}
          <div
            css={css`
              background-image: url("/svg/arrowrightwhite.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              height: 1.5rem;
              width: 1.5rem;
              margin-left: 5px;
            `}
          ></div>
        </div>
      </div>
    </div>
  )
}
