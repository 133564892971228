/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import { Column, H1, H4, H6, Row, Section } from "@src/components"
import Layout from "@src/components/layout"
import { colors } from "@src/style"
import Image from "gatsby-image"
import { Multiselect } from "multiselect-react-dropdown"
import { useState } from "react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { uniq } from "lodash"
import SEO from "@src/components/seo"
import BackgroundImage from "gatsby-background-image"

const multiSelectStyle = {
  multiselectContainer: {
    // To change css for multiselect (Width,height,etc..)},
    "font-family": "GT Flexa Trial",
    "font-size": "20px",
    "font-style": "normal",
    "font-weight": "400",
    "line-height": "30px",
    "letter-spacing": "0px",
  },
  searchBox: {
    border: `2px solid ${colors.black}`,
    "border-radius": 0,
    "margin-bottom": "16px",
    cursor: "pointer",
    display: "flex",
    "align-items": "center",
  },
  inputField: {
    // To change input field position or margin
    "border-radius": "0px",
  },
  chips: {
    // To change css chips(Selected options)
    background: colors.red,
    padding: "0px 8px",
    margin: "0px 4px",
    "border-radius": "0px",
    "text-transform": "uppercase",
  },
  optionContainer: {
    // To change css for option container
    border: "2px solid",
    "margin-top": "-16px",
    // position: "absolute",
    "border-radius": "0px",
    background: colors.black,
  },
  option: {
    // To change css for dropdown options
    color: colors.white,
    background: colors.black,
    "font-family": "Everett",
    "text-transform": "capitalize",
    fontSize: 18,
  },
  groupHeading: {
    // To chanage group heading style
  },
}

type Props = {
  data: any
  locale: "nl" | "en"
  fixedHeight?: boolean
}

export const MagazineBase: React.FC<Props> = ({
  data,
  locale,
  fixedHeight = false,
}) => {
  const {
    allDatoCmsMagazineItem: { edges },
    datoCmsSiteConfig: { highlightedMagazineItem },
  } = data
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [selectedAuthorTags, setSelectedAuthorTags] = useState<string[]>([])

  return (
    <Layout locale={locale}>
      <Section
        background={"white"}
        noReveal
        css={css`
          padding: 64px 16px;
        `}
      >
        <H1>{locale === "nl" ? "Verdieping" : "Magazine"}</H1>
        <div
          css={css`
            input {
              text-transform: uppercase !important;
            }
            .optionContainer li:hover {
              background: ${colors.red} !important;
            }
          `}
        >
          <Row>
            <Column>
              <Multiselect
                style={multiSelectStyle}
                isObject={false}
                placeholder={
                  locale === "nl" ? "filter op type" : "filter by type"
                }
                selectedValues={selectedTags}
                onSelect={(list: string[]) => setSelectedTags(list)}
                onRemove={(list: string[]) => setSelectedTags(list)}
                options={uniq(
                  edges.reduce((acc: string[], { node }: { node: any }) => {
                    const tags = node.tags ? JSON.parse(node.tags) : []
                    for (let tag of tags) {
                      if (!acc.includes(tag)) {
                        acc.push(tag.toLowerCase())
                      }
                    }
                    return acc
                  }, [])
                )}
              />
            </Column>
            <Column>
              <Multiselect
                style={multiSelectStyle}
                isObject={false}
                placeholder={
                  locale === "nl" ? "filter op auteur" : "filter by author"
                }
                selectedValues={selectedAuthorTags}
                onSelect={(list: string[]) => setSelectedAuthorTags(list)}
                onRemove={(list: string[]) => setSelectedAuthorTags(list)}
                options={uniq(
                  edges.reduce((acc: string[], { node }: any) => {
                    const tags = node.tagsAuthor
                      ? JSON.parse(node.tagsAuthor)
                      : []
                    for (let tag of tags) {
                      if (!acc.includes(tag)) {
                        acc.push(tag.toLowerCase())
                      }
                    }
                    return acc
                  }, [] as string[])
                )}
              />
            </Column>
          </Row>
        </div>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter={16}>
            {edges
              .filter(({ node }: { node: any }) => {
                const tags = node.tags
                  ? JSON.parse(node.tags).map((tag: string) =>
                      tag.toLowerCase()
                    )
                  : []
                const authorTags = node.tagsAuthor
                  ? JSON.parse(node.tagsAuthor).map((tag: string) =>
                      tag.toLowerCase()
                    )
                  : []
                if (!node.slug) {
                  return false
                }
                if (!selectedTags.length && !selectedAuthorTags.length) {
                  return true
                }
                let selected = false
                for (let selectedTag of selectedTags) {
                  if (tags.includes(selectedTag)) {
                    selected = true
                  }
                }
                for (let selectedTag of selectedAuthorTags) {
                  if (authorTags.includes(selectedTag)) {
                    selected = true
                  }
                }
                return selected
              })
              .sort(({ node }: { node: any }) =>
                node.id === highlightedMagazineItem.id ? -1 : 1
              )
              .map(
                magazineListItem.bind(this, {
                  locale,
                  fixedHeight,
                  selectedTags,
                  setSelectedTags,
                  selectedAuthorTags,
                  setSelectedAuthorTags,
                  highlightedMagazineItem,
                })
              )}
          </Masonry>
        </ResponsiveMasonry>
      </Section>
    </Layout>
  )
}

export function magazineListItem(
  {
    locale,
    selectedTags,
    fixedHeight,
    setSelectedTags,
    selectedAuthorTags,
    setSelectedAuthorTags,
    highlightedMagazineItem,
  }: {
    locale: "nl" | "en"
    fixedHeight?: boolean
    selectedTags?: string[]
    setSelectedTags?: React.Dispatch<React.SetStateAction<string[]>>
    selectedAuthorTags?: string[]
    setSelectedAuthorTags?: React.Dispatch<React.SetStateAction<string[]>>
    highlightedMagazineItem?: any
  },
  { node }: { node: any },
  i: number
) {
  const tags = (node.tags
    ? JSON.parse(node.tags.toLowerCase())
    : []) as string[]
  const authorTags = (node.tagsAuthor
    ? JSON.parse(node.tagsAuthor.toLowerCase())
    : []) as string[]
  const publishDate = node.meta ? node.meta.firstPublishedAt : null
  const highlighted = node.id === highlightedMagazineItem.id
  return (
    <div
      css={css`
        margin-bottom: 16px;
        background: ${highlighted ? colors.red : "none"};
        color: ${highlighted ? colors.white : colors.black};
        padding: ${highlighted ? "16px" : "0"};
      `}
      key={`magazine-list-item-${i}-${
        locale == "nl"
          ? `/verdieping/${node.slug}`
          : `/en/magazine/${node.slug}`
      }`}
    >
      <a
        href={
          locale == "nl"
            ? `/verdieping/${node.slug}`
            : `/en/magazine/${node.slug}`
        }
        css={css`
          text-decoration: none;
        `}
      >
        {node.thumbnail && (
          <div
            css={css`
              padding-top: ${fixedHeight
                ? 100 / (16 / 9)
                : 100 / node.thumbnail.fluid.aspectRatio}%;
              position: relative;
              overflow: hidden;
            `}
          >
            <Image
              css={css`
                position: absolute !important;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(1);
                width: 100%;
                height: 100%;
                *:hover > * > & {
                  transform: translate(-50%, -50%) scale(1.2);
                }
              `}
              fluid={node.thumbnail.fluid}
            />
          </div>
        )}
        {publishDate && (
          <H6
            css={css`
              margin: 20px 0 8px;
              text-align: left;
            `}
          >
            {new Date(publishDate).toLocaleDateString(locale, {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </H6>
        )}
        <H4
          css={css`
            text-align: left;
            text-decoration: none;
            margin: 4px 0;
          `}
        >
          {node.title}
        </H4>
      </a>
      <div
        css={css`
          text-align: left;
        `}
      >
        {tags.map((tag, j) => {
          const selected = selectedTags ? selectedTags.includes(tag) : false
          return (
            <div
              key={`tag-${i}-${j}`}
              css={css`
                font-family: Snv Cond D Fixed;
                text-transform: uppercase;
                border: 2px solid ${selected ? colors.white : colors.black};
                background: ${selected ? colors.black : colors.white};
                color: ${selected ? colors.white : colors.black};
                display: inline-block;
                padding: 2px 4px;
                margin-right: 4px;
                cursor: pointer;
                &:hover {
                  background: ${selected ? colors.white : colors.black};
                  color: ${selected ? colors.black : colors.white};
                }
              `}
              onClick={() =>
                setSelectedTags &&
                setSelectedTags(
                  selected && selectedTags
                    ? selectedTags?.filter(selectedTag => selectedTag !== tag)
                    : selectedTags
                    ? [...selectedTags, tag]
                    : [tag]
                )
              }
            >
              {tag}
            </div>
          )
        })}
        {authorTags.map((tag, j) => {
          const selected = selectedAuthorTags
            ? selectedAuthorTags.includes(tag)
            : false
          return (
            <div
              key={`tag-${i}-${j}`}
              css={css`
                font-family: Snv Cond D Fixed;
                text-transform: uppercase;
                border: 2px solid ${selected ? colors.white : colors.black};
                background: ${selected ? colors.black : colors.white};
                color: ${selected ? colors.white : colors.black};
                display: inline-block;
                padding: 2px 4px;
                margin-right: 4px;
                cursor: pointer;
                &:hover {
                  background: ${selected ? colors.white : colors.black};
                  color: ${selected ? colors.black : colors.white};
                }
              `}
              onClick={() =>
                setSelectedAuthorTags &&
                setSelectedAuthorTags(
                  selected && selectedAuthorTags
                    ? selectedAuthorTags?.filter(
                        selectedTag => selectedTag !== tag
                      )
                    : selectedAuthorTags
                    ? [...selectedAuthorTags, tag]
                    : [tag]
                )
              }
            >
              {tag}
            </div>
          )
        })}
      </div>
    </div>
  )
}
