/* @jsx jsx */

import React, { Suspense, useState, useRef, useEffect } from "react"
import { Canvas } from "react-three-fiber"
import { OrbitControls, Html } from "@react-three/drei"
import HDRI from "./reactthreefiber/hdri"
import { Model } from "./reactthreefiber/loader"
import { PointManager } from "./reactthreefiber/pointManager"
import { Lights } from "./reactthreefiber/lights"
import { css, jsx } from "@emotion/react"
import { ResizeObserver } from "@juggle/resize-observer"
import "pepjs"
import { mobileBreakPoint } from "./3dkoetsApp"

export function CanvasManager({
  changeZoomPoint,
  zoomPoints,
  userClicked,
  zoomInAnimation,
  visible,
  width,
  fadeInOnLoad,
  locale,
}) {
  const camData = useRef()
  const [pointHovering, setPointHovering] = useState(false)

  return (
    <Canvas
      resize={{ polyfill: ResizeObserver } as ResizeOptions}
      className={visible ? "show" : "hide"}
      //colorManagement
      camera={{ position: [-10, 2, 10], fov: 30, far: 20000 }}
      gl={{ antialias: true }}
      shadowMap
      css={css`
        transition: opacity 1s;
        &:hover {
          cursor: grab;
        }
        &:active {
          cursor: grabbing;
        }
        ${pointHovering ? "cursor: pointer;" : "cursor: grab;"} {
          cursor: pointer;
        }
      `}
    >
      <Suspense fallback={<AppLoading />}>
        <group>
          <Model
            position={[0, 3, -3]}
            scale={[8, 8, 8]}
            fadeInOnLoad={fadeInOnLoad}
          />
          <PointManager
            zoomPoints={zoomPoints}
            changeZoomPoint={changeZoomPoint}
            setPointHovering={setPointHovering}
            zoomInAnimation={zoomInAnimation}
          />
          <mesh
            receiveShadow
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -4, 0]}
          ></mesh>
        </group>
        <HDRI />
        <Lights camData={camData} />
      </Suspense>

      <OrbitControls
        ref={camData}
        enableZoom={false}
        minDistance={width < mobileBreakPoint ? (width < 600 ? 150 : 120) : 100}
        maxPolarAngle={1.1}
        minPolarAngle={1.1}
        autoRotate
        autoRotateSpeed={userClicked ? 0 : 0.8}
        rotateSpeed={0.5}
        dampingFactor={0.2}
      ></OrbitControls>
    </Canvas>
  )
}

const AppLoading = () => {
  return " "
}
