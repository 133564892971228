/** @jsx jsx */

import { jsx, css } from "@emotion/react"
import { amsterdamMuseumLogo } from "@src/assets/images"
import {
  Banner,
  DoubleImage,
  Intro,
  Quote,
  TextBlock,
  TextImageBlock,
} from "@src/components"
import SEO from "@src/components/seo"
import { KoetsApp } from "@src/koetsApp/components/3dkoetsApp"
import { colors, desktop, mobile } from "@src/style"
import { graphql, StaticQuery } from "gatsby"
import { Fragment } from "react"
import { FiChevronDown } from "react-icons/fi"
import { EventsList } from "./events-list"
import Layout from "./layout"
import { MagazineList } from "./magazine-list"
import { Socials } from "./socials"
import { TextImageBlockLeft } from "./textImageBlockLeft"

export function Page({
  title,
  content,
  locale = "nl",
  interactiveHeader = false,
}: {
  title?: string | null
  content: any
  locale?: "nl" | "en"
  interactiveHeader?: boolean
}) {
  return (
    <Layout locale={locale} useTransparentHeader={interactiveHeader}>
      {PageComponent(title, interactiveHeader, content, locale)}
    </Layout>
  )
}

export const PageComponent = (
  title: string | null | undefined,
  interactiveHeader: boolean,
  content: any,
  locale: string
) => {
  return (
    <StaticQuery
      query={graphql`
        query pageQuery {
          datoCmsSiteConfig {
            facebookUrl
            faqUrl
            twitterUrl
            voorwaardenUrl
            instagramUrl
            privacyUrl
            ticketsUrl
            ticketsButtonText
          }

          allDatoCmsKoetszoompuntenNlEng {
            edges {
              node {
                id
                titel
                locale
                achtergrondAfbeelding {
                  fluid(
                    maxWidth: 1900
                    imgixParams: { fm: "png", auto: "compress" }
                  ) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
                beschrijvingNode {
                  childMarkdownRemark {
                    html
                  }
                }
                leesMeerLink
                youtubeLink
                idNummer
              }
            }
          }
        }
      `}
      render={(data: any) => {
        const zoomPuntData = data.allDatoCmsKoetszoompuntenNlEng
        return (
          <Fragment>
            <SEO title={title} />
            {interactiveHeader && (
              <div
                css={css`
                  position: relative;
                  color: ${colors.white};
                  height: 100vh;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: flex-start;
                  padding: 0px 30px 40px 60px;
                `}
              >
                <KoetsApp
                  zoomPuntData={zoomPuntData}
                  mobile={mobile}
                  datoCmsSiteConfig={data.datoCmsSiteConfig}
                  locale={locale}
                />
                <div css={css`
                display: none;
                ${mobile}{
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 33%;
                  bottom: 0;
                  left: 0;
                  z-index: 1;
                }`}></div>

                <img
                  css={css`
                    width: 224px;
                    z-index: 1;
                    ${mobile} {
                      display: none;
                    }
                    margin-top: 30px;
                  `}
                  src={amsterdamMuseumLogo}
                  alt="Amsterdam Museum"
                />
                <div
                  css={css`
                    position: absolute;
                    bottom: 20%;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    text-align: center;
                    ${desktop} {
                      display: none;
                    }
                  `}
                >
                  <a
                    css={css`
                      border: 1px solid ${colors.white};
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 50%;
                      width: 43px;
                      height: 43px;
                      font-size: 28px;
                      cursor: pointer;
                    `}
                    onClick={() =>
                      document.getElementById("intro")!.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                  >
                    <FiChevronDown />
                  </a>
                </div>
              </div>
            )}
            {content &&
              content.map((contentNode: any, i: number) => {
                const key = `content-item${i}`
                const noReveal = i === 0
                if (!contentNode.model) {
                  return null
                }
                switch (contentNode.model.name) {
                  case "banner": {
                    const { image } = contentNode
                    return <Fragment key={key}>{Banner(image)}</Fragment>
                  }
                  case "intro": {
                    const {
                      bodyNode,
                      shareButton,
                      subtitle,
                      supertitle,
                      title,
                    } = contentNode
                    return (
                      <Fragment key={key}>
                        {Intro(
                          supertitle,
                          title,
                          shareButton,
                          subtitle,
                          bodyNode
                        )}
                      </Fragment>
                    )
                  }
                  case "double image": {
                    const { image1, image2 } = contentNode
                    return (
                      <Fragment key={key}>
                        {DoubleImage(image1, image2)}
                      </Fragment>
                    )
                  }
                  case "text block": {
                    const {
                      title,
                      bodyNode,
                      linkText,
                      linkUrl,
                      backgroundColor,
                    } = contentNode
                    return (
                      <Fragment key={key}>
                        {TextBlock(
                          title,
                          bodyNode,
                          linkText,
                          linkUrl,
                          backgroundColor,
                          noReveal
                        )}
                      </Fragment>
                    )
                  }
                  case "quote": {
                    const {
                      quote1,
                      name1,
                      quote2,
                      name2,
                      backgroundColor,
                    } = contentNode
                    return (
                      <Fragment key={key}>
                        {Quote(
                          quote1,
                          name1,
                          quote2,
                          name2,
                          backgroundColor,
                          noReveal
                        )}
                      </Fragment>
                    )
                  }
                  case "text image block": {
                    const {
                      title,
                      superTitle,
                      bodyNode,
                      linkText,
                      linkUrl,
                      image,
                      backgroundColor,
                    } = contentNode
                    return (
                      <Fragment key={key}>
                        {TextImageBlock(
                          title,
                          superTitle,
                          bodyNode,
                          linkText,
                          linkUrl,
                          image,
                          backgroundColor,
                          locale,
                          noReveal
                        )}
                      </Fragment>
                    )
                  }
                  case "text image block left": {
                    const {
                      bodyNode,
                      linkText,
                      linkUrl,
                      backgroundColor,
                      image,
                    } = contentNode
                    return (
                      <Fragment key={key}>
                        {TextImageBlockLeft({
                          bodyNode,
                          linkText,
                          linkUrl,
                          backgroundColor,
                          image,
                          locale,
                          noReveal,
                        })}
                      </Fragment>
                    )
                  }
                  case "events list": {
                    return (
                      <Fragment key={key}>
                        {EventsList({ locale, events: contentNode, noReveal })}
                      </Fragment>
                    )
                  }
                  case "magazine": {
                    return (
                      <Fragment key={key}>
                        {MagazineList({
                          locale,
                          magazine: contentNode,
                          noReveal,
                        })}
                      </Fragment>
                    )
                  }
                  case "socials": {
                    return (
                      <Fragment key={key}>
                        {Socials(contentNode, noReveal)}
                      </Fragment>
                    )
                  }
                }
              })}
          </Fragment>
        )
      }}
    />
  )
}
