/* @jsx jsx */

import React, { useState, useEffect } from "react"
import { css, jsx } from "@emotion/react"

import { ZoomScreen } from "./zoomScreen"
import { ZoomImg } from "./zoomimg"
import { CanvasManager } from "./CanvasManager"
import { BgLetters } from "./bgLetters"
import { LoadingScreen } from "./loadingscreen"
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"

// import { TouchPoint } from "./touchpoint"
import "../3dApp.css"

import { zoomPointData } from "./zoomPointPositions"

const initBg = "#d8bb6d"
export const mobileBreakPoint = 1000

export function KoetsApp({
  zoomPuntData,
  mobile,
  datoCmsSiteConfig,
  locale,
}: any) {
  const [currentZoomPoint, changeZoomPoint] = useState(0)
  const [userZoom, setUserZoom] = useState(false)
  const [userClicked, setUserClicked] = useState(false)

  const [showCanvas, setShowCanvas] = useState(false)
  const [showFadeLetters, setShowFadeLetters] = useState(false)
  const [showFillLetters, setShowFillLetters] = useState(false)
  const [fadeBgLetters, setFadeBgLetters] = useState(false)
  const [showZoomScreen, setShowZoomScreen] = useState(false)
  const [showZoomImg, setShowZoomImg] = useState(false)
  const [showZoomOverlay, setShowZoomOverlay] = useState(false)
  const [showZoomPoints, setShowZoomPoints] = useState(true)
  const [appLoading, setAppLoading] = useState(true)

  const [width, setWidth] = useState(1100)

  const [bgColor, setBgColor] = useState(initBg)

  useEffect(() => {
    if (window) {
      window.addEventListener("click", () => setUserClicked(true))
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
      setWidth(window.innerWidth)
    }
  }, [])

  // FILTER INCOMING CMS DATA
  const filteredZoomPoints = zoomPuntData.edges
    .map((item: any) => {
      return item.node
    })
    .filter((item: any) => {
      return item.locale == locale
    })

  // ADD ADDITIONAL POINT DATA (POSITION IN 3d SPACE, BACKGROUND COLOR, IMAGE)
  filteredZoomPoints.forEach((point: any, i: number) => {
    zoomPointData.forEach((pointData: any) => {
      if (pointData.id === point.idNummer) {
        point.position = pointData.position
        point.bgColor = pointData.bgColor
      }
    })
  })

  const fadeInOnLoad = () => {
    setShowCanvas(true)
    setShowFadeLetters(true)
    setAppLoading(false)
  }

  const zoomInAnimation = (index: number) => {
    if (!userZoom) {
      changeZoomPoint(index)
      setUserZoom(true)
      setShowCanvas(false)
      setShowFadeLetters(false)
      setShowZoomPoints(false)
      setTimeout(() => {
        if (filteredZoomPoints[index].bgColor) {
          setBgColor(filteredZoomPoints[index].bgColor)
        } else {
          setBgColor(initBg)
        }
        setShowFillLetters(true)
      }, 800)
      setTimeout(() => {
        setFadeBgLetters(true)
        setShowZoomImg(true)
      }, 1800)
      setTimeout(() => {
        setShowZoomPoints(true)
        setShowZoomScreen(true)
        setShowZoomOverlay(true)
      }, 3500)
    } else {
      if (index !== currentZoomPoint) {
        setUserZoom(true)
        setShowZoomScreen(false)
        setShowZoomOverlay(false)
        setShowFillLetters(false)
        setShowZoomImg(false)
        setTimeout(() => {
          if (filteredZoomPoints[index].bgColor) {
            setBgColor(filteredZoomPoints[index].bgColor)
          } else {
            setBgColor(initBg)
          }
        }, 600)
        setTimeout(() => {
          changeZoomPoint(index)
          setShowZoomScreen(true)
          setShowZoomOverlay(true)
          setShowFillLetters(true)
          setShowZoomImg(true)
        }, 1000)
      }
    }
  }

  const backToMain = () => {
    setUserZoom(false)
    setShowZoomScreen(false)
    setShowZoomOverlay(false)
    setShowFillLetters(false)
    setFadeBgLetters(false)
    setShowZoomImg(false)
    setTimeout(() => {
      setShowCanvas(true)
      setShowFadeLetters(true)
      setBgColor(initBg)
    }, 700)
  }

  return (
    // CONTAINER -- kleiner op mobile
    <div
      className="App"
      css={css`
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        transition: background-color 0.7s;
        position: absolute;
        width: 100%;
        height: 100%;
        ${mobile} {
          height: 90%;
        }
        top: 0;
        left: 0;
        z-index: 0;
      `}
    >
      {/* FILLER DIV -- laat ook bij kleinere container de hele achtergrond de goede kleur zijn */}
      <div
        css={css`
          position: absolute;
          width: 100%;
          height: 100vh;
          left: 0;
          top: 0;
          background-color: ${bgColor};
          z-index: -6;
        `}
      ></div>

      {/* FADED GRADIENT BACKGROUND */}
      <div
        css={css`
          position: absolute;
          height: 100%;
          width: 100%;
          background-image: url("/svg/bgfade.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          z-index: -5;
        `}
        className={showCanvas ? "show" : "hide"}
      ></div>
      {/* 3D SPUL */}
      <CanvasManager
        zoomPoints={filteredZoomPoints}
        userClicked={userClicked}
        zoomInAnimation={zoomInAnimation}
        visible={showCanvas}
        width={width}
        fadeInOnLoad={fadeInOnLoad}
      />

      {/* ACHTERGROND LETTERS */}
      <BgLetters
        showFadeLetters={showFadeLetters}
        showFillLetters={showFillLetters}
        fadeBgLetters={fadeBgLetters}
        locale={locale}
      />

      {/* UITGELICHTTE INFO */}
      <ZoomScreen
        title={filteredZoomPoints[currentZoomPoint].titel}
        text={filteredZoomPoints[currentZoomPoint].beschrijvingNode}
        visible={showZoomScreen}
        zoomInAnimation={zoomInAnimation}
        currentZoomPoint={currentZoomPoint}
        amountOfPoints={filteredZoomPoints.length}
        youtubeLink={filteredZoomPoints[currentZoomPoint].youtubeLink}
        leesMeerLink={filteredZoomPoints[currentZoomPoint].leesMeerLink}
        setUserClicked={setUserClicked}
        setUserZoom={setUserZoom}
        backToMain={backToMain}
        width={width}
        userZoom={userZoom}
        locale={locale}
        fluid={
          filteredZoomPoints[currentZoomPoint].achtergrondAfbeelding
            ? filteredZoomPoints[currentZoomPoint].achtergrondAfbeelding.fluid
            : null
        }
      />

      {/* PLAATJE VAN UITGELICHT DEEL */}
      {width > mobileBreakPoint && (
        <ZoomImg
          fluid={
            filteredZoomPoints[currentZoomPoint].achtergrondAfbeelding
              ? filteredZoomPoints[currentZoomPoint].achtergrondAfbeelding.fluid
              : null
          }
          imgVisible={showZoomImg}
          overlayVisible={showZoomOverlay}
          bgColor={bgColor}
        />
      )}

      {/* SOCIAL LINKS (ONLY VISIBLE ON DESKTOP SIZE) */}
      {width > mobileBreakPoint && (
        <div
          className={showFadeLetters ? "show" : "hide"}
          css={css`
            position: absolute;
            right: 100px;
            bottom: 60px;
            height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          `}
        >
          <a
            href={datoCmsSiteConfig.facebookUrl}
            css={css`
              opacity: 0.6;
              &:hover {
                opacity: 1;
              }
            `}
          >
            <FaFacebookF size={20} />
          </a>
          <a
            href={datoCmsSiteConfig.twitterUrl}
            css={css`
              opacity: 0.6;
              &:hover {
                opacity: 1;
              }
            `}
          >
            <FaTwitter size={20} />
          </a>
          <a
            href={datoCmsSiteConfig.instagramUrl}
            css={css`
              opacity: 0.6;
              &:hover {
                opacity: 1;
              }
            `}
          >
            <FaInstagram size={20} />
          </a>
        </div>
      )}

      {/* SCROLL BUTTON (ONLY VISIBLE ON DESKTOP SIZE) */}

      {width > mobileBreakPoint && showFadeLetters && (
        <div
          css={css`
            position: absolute;
            bottom: 0;
            left: 50px;
            height: 90px;
            width: 100px;
            text-align: center;
            cursor: pointer;
            font-family: "Snv Cond D Fixed";
            font-size: 1.5rem;
            opacity: 0.6;
            &:hover {
              opacity: 1;
            }
          `}
          onClick={() =>
            document.getElementById("intro")!.scrollIntoView({
              behavior: "smooth",
            })
          }
        >
          SCROLL
          <div
            css={css`
              width: 1px;
              height: 50px;
              position: absolute;
              left: 50%;
              bottom: 0;
              background-color: white;
            `}
          ></div>
        </div>
      )}

      {/* LOADING SCREEN */}
      {appLoading && <LoadingScreen locale={locale} />}

      {/* TOUCHPOINTS */}
      {/* <div
        css={css`
          position: absolute;
          left: 150px;
          height: 100vh;
          top: 10%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: opacity 1s;
          ${mobile} {
          }
        `}
        className={showZoomPoints ? "show" : "hide"}
      >
        {filteredZoomPoints.map((point: any, index: number) => {
          return (
            <TouchPoint
              currentZoomPoint={currentZoomPoint}
              zoomInAnimation={zoomInAnimation}
              userIsZoomedIn={showZoomScreen}
              key={index}
              index={index}
            />
          )
        })}
      </div> */}
    </div>
  )
}
