/** @jsx jsx */

import { jsx } from "@emotion/react"
import "normalize.css"
import Layout from "./layout-nl"
import LayoutEn from "./layout-en"
import { PropsWithChildren } from "react"

type Props = PropsWithChildren<{
  useTransparentHeader?: boolean
  locale: "nl" | "en"
  headerColor?: Color
}>

const LocalizedLayout = ({ locale, ...props }: Props) => {
  // console.log(new Error("localizedLayout").stack)
  if (locale === "nl") {
    return <Layout {...props} />
  } else {
    return <LayoutEn {...props} />
  }
}

export default LocalizedLayout
