/** @jsx jsx */

import { jsx, css } from "@emotion/react"
import BackgroundImage from "gatsby-background-image"
import { Section, H1, Button, Body, Column, CTA, Row } from "@src/components"
import { graphql, StaticQuery } from "gatsby"
import { desktop, mobile } from "@src/style"
import Img from "gatsby-image"
import { Fragment } from "react"
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa"

type Props = {
  backgroundColor: Color
  title: string
  bodyNode: {
    childMarkdownRemark: {
      html: string
    }
  }
  instaImage1: any
  instaUrl1: string
  instaImage2: any
  instaUrl2: string
  instaImage3: any
  instaUrl3: string
  instaImage4: any
  instaUrl4: string
}

export const Socials: React.FC<Props> = (
  {
    backgroundColor = "oldRose",
    title,
    bodyNode,
    instaImage1,
    instaUrl1,
    instaImage2,
    instaUrl2,
    instaImage3,
    instaUrl3,
    instaImage4,
    instaUrl4,
  },
  noReveal
) => {
  return (
    <StaticQuery
      query={graphql`
        query socialsQuery {
          datoCmsSiteConfig {
            facebookUrl
            twitterUrl
            instagramUrl
          }
        }
      `}
      render={(data: any) => {
        return (
          <Fragment>
            <Section
              background={backgroundColor ? backgroundColor : "oldRose"}
              css={css`
                ${desktop} {
                  text-align: center;
                }
              `}
              noReveal={noReveal}
            >
              <H1>{title}</H1>
              {bodyNode && (
                <Body
                  dangerouslySetInnerHTML={{
                    __html: bodyNode.childMarkdownRemark!.html!,
                  }}
                />
              )}
              <div
                css={css`
                  font-size: 25px;
                  ${desktop} {
                    font-size: 46px;
                  }
                `}
              >
                <a href={data.datoCmsSiteConfig.facebookUrl}>
                  <FaFacebookF />
                </a>{" "}
                <a href={data.datoCmsSiteConfig.twitterUrl}>
                  <FaTwitter />
                </a>{" "}
                <a href={data.datoCmsSiteConfig.instagramUrl}>
                  <FaInstagram />
                </a>
              </div>
            </Section>
            <div
              css={css`
                height: 25vw;
                ${mobile} {
                  height: 100vw;
                }
              `}
            >
              {[
                { image: instaImage1, url: instaUrl1 },
                { image: instaImage2, url: instaUrl2 },
                { image: instaImage3, url: instaUrl3 },
                { image: instaImage4, url: instaUrl4 },
              ].map((instaImage: any, i: number) =>
                instaImage.image ? (
                  <a
                    key={`insta-image-${i}`}
                    href={instaImage.url}
                    css={css`
                      float: left;
                      width: 25%;
                      height: 100%;
                      ${mobile} {
                        width: 50%;
                        height: 50%;
                      }
                    `}
                    target="_blank"
                  >
                    <BackgroundImage
                      // @ts-ignore
                      fluid={instaImage.image.fluid}
                      css={css`
                        width: 100%;
                        height: 100%;
                      `}
                    />
                  </a>
                ) : null
              )}
            </div>
          </Fragment>
        )
      }}
    />
  )
}

export const socialsQuery = graphql`
  fragment SocialFragment on DatoCmsSocial {
    model {
      name
    }
    id
    title
    bodyNode {
      childMarkdownRemark {
        html
      }
    }
    instaImage1 {
      fluid(maxWidth: 950, imgixParams: { fm: "png", auto: "compress" }) {
        base64
        aspectRatio
        src
        srcSet
        sizes
      }
    }
    instaUrl1
    instaImage2 {
      fluid(maxWidth: 950, imgixParams: { fm: "png", auto: "compress" }) {
        base64
        aspectRatio
        src
        srcSet
        sizes
      }
    }
    instaUrl2
    instaImage3 {
      fluid(maxWidth: 950, imgixParams: { fm: "png", auto: "compress" }) {
        base64
        aspectRatio
        src
        srcSet
        sizes
      }
    }
    instaUrl3
    instaImage4 {
      fluid(maxWidth: 950, imgixParams: { fm: "png", auto: "compress" }) {
        base64
        aspectRatio
        src
        srcSet
        sizes
      }
    }
    instaUrl4
  }
`
