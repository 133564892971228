/** @jsx jsx */

import { jsx } from "@emotion/react"
import { Section, H1, Button, Body } from "@src/components"
import { Column, Row } from "@src/components/grid"
import { graphql } from "gatsby"

export function TextBlock(
  title: any,
  bodyNode: any,
  linkText: any,
  linkUrl: any,
  backgroundColor: Color = "white",
  noReveal?: boolean
) {
  return (
    <Section
      background={backgroundColor ? backgroundColor : "white"}
      noReveal={noReveal}
    >
      <Row>
        {title && (
          <Column fixed>
            <H1>{title}</H1>
          </Column>
        )}
        <Column>
          <Body
            dangerouslySetInnerHTML={{
              __html: bodyNode.childMarkdownRemark.html,
            }}
          />
          {linkText && linkUrl && (
            <Button>
              <a href={linkUrl}>{linkText}</a>
            </Button>
          )}
        </Column>
      </Row>
    </Section>
  )
}

export const textBlockQuery = graphql`
  fragment TextBlockFragment on DatoCmsTextBlock {
    model {
      name
    }
    id
    bodyNode {
      childMarkdownRemark {
        html
      }
    }
    backgroundColor
    linkText
    linkUrl
    title
  }
`
