/** @jsx jsx */

import { css, jsx, SerializedStyles } from "@emotion/react"
import { colors } from "@src/style"
import React, { HTMLProps, PropsWithChildren } from "react"
import { FiChevronLeft, FiChevronRight, FiChevronUp } from "react-icons/fi"
import { Body } from "./typography"
import {
  VscArrowRight,
  VscArrowLeft,
  VscArrowUp,
  VscArrowDown,
} from "react-icons/vsc"
import { divide } from "lodash"

type ButtonProps = PropsWithChildren<{
  primary?: boolean
  inverted?: boolean
  hoverColor?: "red" | "black" | "white" | "gold"
  hoverBackground?: "red" | "black" | "white" | "gold"
  hoverBorder?: "red" | "black" | "white" | "gold"
  borderColor?: "red" | "black" | "white" | "gold"
  css?: SerializedStyles
}>

export const Button: React.FC<ButtonProps> = ({
  children,
  primary,
  inverted,
  hoverColor,
  hoverBackground,
  hoverBorder,
  borderColor,
  css: cssProp,
  ...props
}) => (
  <div
    css={[
      css`
        background: ${primary
          ? colors.red
          : inverted
          ? colors.white
          : colors.black};
        color: ${inverted ? colors.black : colors.white};
        padding: 12px;
        font-family: Everett;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        height: 42px;
        min-width: 120px;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid;
        border-radius: 21px;
        border-color: ${borderColor ? borderColor : "transparent"};
        &:hover {
          color: ${hoverColor
            ? hoverColor
            : primary
            ? colors.white
            : inverted
            ? colors.white
            : colors.white};
          background-color: ${hoverBackground
            ? hoverBackground
            : inverted
            ? colors.black
            : primary
            ? colors.brown
            : colors.offBlack};
          border-color: ${hoverBorder
            ? hoverBorder
            : primary
            ? colors.brown
            : colors.offBlack};
        }
        a {
          color: inherit;
          text-decoration: none;
        }
      `,
      cssProp,
    ]}
    {...props}
  >
    <span>{children}</span>
    <VscArrowRight
      css={css`
        margin-left: 4px;
      `}
    />
  </div>
)

export const CircledChevron: React.FC<{
  direction: "right" | "left" | "up"
  color: "red" | "black" | "white" | "gold"
  hoverColor: "red" | "black" | "white" | "gold"
  css?: any
  onClick?: () => void
}> = ({ direction, color, hoverColor, css: cssProp, onClick, ...props }) => {
  return (
    <div
      css={[
        css`
          border: 1px solid ${colors[color]};
          color: ${colors[color]};
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 35px;
          height: 35px;
          margin-left: 21px;
          font-size: 20px;
          *:hover > & {
            border-color: ${colors[hoverColor]};
            color: ${colors[hoverColor]};
          }
        `,
        cssProp,
      ]}
      onClick={() => onClick && onClick()}
      {...props}
    >
      {direction === "right" && <VscArrowRight />}
      {direction === "left" && <VscArrowLeft />}
      {direction === "up" && <VscArrowUp />}
    </div>
  )
}
export const CircledChevronLarge: React.FC<{
  direction: "right" | "left" | "up"
  color: "red" | "black" | "white" | "gold"
  hoverColor: "red" | "black" | "white" | "gold"
  css?: any
  onClick?: () => void
}> = ({ direction, color, hoverColor, css: cssProp, onClick, ...props }) => {
  return (
    <div
      css={[
        css`
          border: 3px solid ${colors[color]};
          color: ${colors[color]};
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 67px;
          height: 67px;
          margin-left: 21px;
          font-size: 42px;
          *:hover > & {
            border-color: ${colors[hoverColor]};
            color: ${colors[hoverColor]};
          }
        `,
        cssProp,
      ]}
      onClick={() => onClick && onClick()}
      {...props}
    >
      {direction === "right" && <VscArrowRight />}
      {direction === "left" && <VscArrowLeft />}
      {direction === "up" && <VscArrowUp />}
    </div>
  )
}

export const LinkButton: React.FC<{
  color?: "red" | "white" | "black" | "green"
  hoverColor?: "red" | "white" | "black"
  direction?: "up" | "right" | "left"
  onClick?: () => void
  css?: any
}> = ({
  children,
  color = "red",
  hoverColor = "black",
  direction = "right",
  onClick,
  css: cssProp,
  ...props
}) => {
  return (
    <Body
      css={[
        css`
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          color: ${colors[color]};
          min-width: 180px;
          cursor: pointer;
          padding: 12px 0;
          &:hover {
            color: ${colors[hoverColor]};
          }

          a {
            color: inherit;
            text-decoration: none;
          }
        `,
        cssProp,
      ]}
      onClick={onClick}
      {...props}
    >
      {children}
      <CircledChevron {...{ direction, color, hoverColor }} />
    </Body>
  )
}

export const CTA: React.FC<PropsWithChildren<
  {
    css?: SerializedStyles
    direction?: "right" | "left" | "up" | "down" | "none"
  } & HTMLProps<HTMLAnchorElement>
>> = ({ css: cssProp, direction = "right", children, ...props }) => (
  <a
    css={[
      css`
        font-family: GT Flexa Trial;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: left;
        text-transform: uppercase;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          font-style: italic;
        }
      `,
      cssProp,
    ]}
    {...props}
  >
    {children}
    <div>
      {direction === "right" && (
        <VscArrowRight
          css={css`
            margin: 0px 0px 4px 4px;
          `}
        />
      )}
      {direction === "left" && (
        <VscArrowLeft
          css={css`
            margin: 0px 0px 4px 4px;
          `}
        />
      )}
      {direction === "up" && (
        <VscArrowUp
          css={css`
            margin: 0px 0px 0px 4px;
          `}
        />
      )}
      {direction === "down" && (
        <VscArrowDown
          css={css`
            margin: 0px 0px 0px 4px;
          `}
        />
      )}
    </div>
  </a>
)
