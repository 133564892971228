/** @jsx jsx */

import { css, jsx } from "@emotion/react"
import { amsterdamMuseumLogo } from "@src/assets/images"
import { colors, desktop, mobile } from "@src/style"
import React, { Fragment, useCallback, useEffect, useState } from "react"
import { VscChromeClose } from "react-icons/vsc"
import { LinkButton } from "./buttons"
import { Row } from "./grid"
import { NavBar } from "./NavBar"

const HeaderBase: ({
  useTransparentHeader,
  headerColor,
  locale,
}: {
  useTransparentHeader?: boolean
  headerColor?: Color
  locale: "nl" | "en"
}) => React.FC<any> = ({
  useTransparentHeader,
  headerColor,
  locale,
}) => data => {
  const [showNav, setShowNav] = useState(true)
  const [transparentNav, setTransparentNav] = useState(useTransparentHeader)
  const [lastYOffset, setLastYOffset] = useState<number | null>(null)
  const [showMenu, setShowMenu] = useState(false)
  const handleScroll = useCallback(() => {
    if (window.pageYOffset < window.innerHeight / 3) {
      setShowNav(true)
      setTransparentNav(useTransparentHeader)
    } else {
      if (window.pageYOffset > window.innerHeight * 0.8) {
        setTransparentNav(false)
        setShowNav(true)
      } else if (transparentNav) {
        setShowNav(false)
      }
    }
    setLastYOffset(window.pageYOffset)
  }, [lastYOffset])
  useEffect(() => handleScroll(), [showMenu])
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })
  useEffect(() => handleScroll(), [])
  const {
    headerTitle,
    homeUrl,
    ticketsUrl,
    ticketsButtonText,
    menuOnderzoek,
    menuTentoonstelling,
    menuDesktop,
    enableMenuOnderzoek,
    enableMenuTentoonstelling,
    enableMenuDesktop,
  } = data.datoCmsSiteConfig
  return (
    <Fragment>
      <NavBar
        {...{
          headerTitle,
          homeUrl,
          ticketsUrl,
          ticketsButtonText,
          menuDesktop,
          enableMenuDesktop,
          transparentNav,
          showNav,
          showMenu,
          setShowMenu,
          headerColor,
        }}
      />

      <div
        css={css`
          position: fixed;
          top: 0px;
          left: 0;
          right: 0;
          bottom: 0;
          transition: all 0.5s;
          transform: translateY(${showMenu ? 0 : -200}%);
          z-index: 1001;
          ${mobile} {
            top: 0;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        `}
      >
        {!showMenu && (
          <NavBar
            {...{
              headerTitle,
              homeUrl,
              ticketsUrl,
              ticketsButtonText,
              menuDesktop,
              enableMenuDesktop,
              transparentNav: false,
              showNav: true,
              showMenu: true,
              setShowMenu,
              headerColor,
            }}
          />
        )}
        <Row
          css={css`
            position: relative;
            ${desktop} {
              height: 100%;
            }
          `}
        >
          <img
            css={css`
              width: 187px;
              max-width: 17vw;
              position: absolute;
              top: 40px;
              left: 40px;
              ${mobile} {
                display: none;
              }
            `}
            src={amsterdamMuseumLogo}
          />
          <div
            css={css`
              position: absolute;
              top: 0px;
              right: 0px;
              padding: 30px;
              color: ${colors.white};
              font-size: 24px;
              cursor: pointer;
              box-sizing: border-box;
              display: block;
              ${mobile} {
                color: ${colors.green};
              }
            `}
            onClick={() => setShowMenu(false)}
          >
            <VscChromeClose />
          </div>
         
          {enableMenuTentoonstelling && (
            <div
              css={css`
                flex: 1;
                background-color: ${colors.white};
                color: ${colors.green};
                display: flex;
                justify-content: center;
                padding-top: 80px;
                padding-bottom: 40px;
                ${desktop} {
                  height: 100%;
                  overflow-y: auto;
                  -ms-overflow-style: none;
                  scrollbar-width: none;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                }
                ${mobile} {
                  min-height: 100vh;
                }
              `}
            >
              <div
                css={css`
                  width: 50%;
                  max-width: 740px;
                  ${mobile} {
                    width: 80%;
                  }
                `}
              >
                {enableMenuTentoonstelling && enableMenuOnderzoek && (
                  <div
                    css={css`
                      font-family: GT Flexa Trial;
                      text-transform: uppercase;
                      font-size: 30px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 40px;
                      letter-spacing: -0.02em;
                      text-align: center;
                      margin-bottom: 40px;
                    `}
                  >
                    {locale === "nl" ? "Tentoonstelling" : "Exhibition"}
                  </div>
                )}
                <div>
                  {menuTentoonstelling.map(
                    (menuItem: { title: string; url: string }, i: number) => (
                      <a
                        key={`menuTentoonstelling-${i}`}
                        css={css`
                          min-height: 88px;
                          border-bottom: 1px solid ${colors.green};
                          color: ${colors.green};
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          padding: 8px 27px 8px 40px;
                          ${mobile} {
                            padding-left: 27px;
                            justify-content: flex-start;
                          }
                          text-decoration: none;
                          &:first-of-type {
                            /* border-top: 1px solid ${colors.green}; */
                          }
                          &:hover {
                            color: ${colors.red};
                          }
                        `}
                        href={menuItem.url}
                      >
                        <div
                          css={css`
                            //styleName: Desktop / H4;
                            font-family: GT Flexa Trial;
                            font-size: 36px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 36px;
                            letter-spacing: 0em;
                            text-align: left;
                            text-transform: uppercase;
                            ${mobile} {
                              /* text-align: center; */
                              margin: 16px 0;
                            }
                          `}
                        >
                          {menuItem.title}
                        </div>
                        <div className="hide-mobile">
                          <LinkButton
                            color="green"
                            hoverColor="red"
                            css={css`
                              min-width: 0px;
                              margin-bottom: 0px;
                              margin-left: 16px;
                              a:hover & {
                                > div {
                                  color: ${colors.red} !important;
                                  border-color: ${colors.red};
                                }
                              }
                            `}
                          />
                        </div>
                      </a>
                    )
                  )}
                </div>
              </div>
            </div>
          )}

          {enableMenuOnderzoek && (
            <div
              css={css`
                flex: 1;
                background-color: ${colors.green};
                color: ${colors.white};
                display: flex;
                justify-content: center;
                padding-top: 80px;
                padding-bottom: 40px;
                ${desktop} {
                  height: 100%;
                  overflow-y: auto;
                  -ms-overflow-style: none;
                  scrollbar-width: none;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                }
                ${mobile} {
                  min-height: 100vh;
                }
              `}
            >
              <div
                css={css`
                  width: 50%;
                  max-width: 740px;
                  ${mobile} {
                    width: 80%;
                  }
                `}
              >
                {enableMenuTentoonstelling && enableMenuOnderzoek && (
                  <div
                    css={css`
                      font-family: GT Flexa Trial;
                      text-transform: uppercase;
                      font-size: 30px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 40px;
                      letter-spacing: -0.02em;
                      text-align: center;
                      margin-bottom: 40px;
                    `}
                  >
                    {locale === "nl" ? "Onderzoek" : "Research"}
                  </div>
                )}
                <div>
                  {menuOnderzoek.map(
                    (menuItem: { title: string; url: string }, i: number) => (
                      <a
                        key={`menuOnderzoek-${i}`}
                        css={css`
                          min-height: 88px;
                          border-bottom: 1px solid ${colors.white};
                          color: ${colors.white};
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          padding: 8px 27px 8px 40px;
                          ${mobile} {
                            padding-left: 27px;
                            justify-content: flex-start;
                          }
                          text-decoration: none;
                          &:first-of-type {
                            /* border-top: 1px solid ${colors.white}; */
                          }
                          &:hover {
                            color: ${colors.red};
                          }
                        `}
                        href={menuItem.url}
                      >
                        <div
                          css={css`
                            //styleName: Desktop / H4;
                            font-family: GT Flexa Trial;
                            font-size: 36px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 36px;
                            letter-spacing: 0em;
                            text-align: left;
                            text-transform: uppercase;
                            ${mobile} {
                              /* text-align: center; */
                              margin: 16px 0;
                            }
                          `}
                        >
                          {menuItem.title}
                        </div>
                        <div className="hide-mobile">
                          <LinkButton
                            color="white"
                            hoverColor="red"
                            css={css`
                              min-width: 0px;
                              margin-bottom: 0px;
                              margin-left: 16px;
                              a:hover & {
                                > div {
                                  color: ${colors.red} !important;
                                  border-color: ${colors.red};
                                }
                              }
                            `}
                          />
                        </div>
                      </a>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </Row>
      </div>
    </Fragment>
  )
}

export default HeaderBase
