/** @jsx jsx */

import { jsx, css } from "@emotion/react"
import BackgroundImage from "gatsby-background-image"
import { Section, H1, Button, Body, H4 } from "@src/components"
import { graphql } from "gatsby"
import { colors, desktop, mobile } from "../style/variables"
import Img from "gatsby-image"

export function TextImageBlock(
  title: any,
  superTitle: any,
  bodyNode: any,
  linkText: any,
  linkUrl: any,
  image: any,
  backgroundColor: Color = "red",
  locale: string,
  noReveal?: boolean
) {
  return (
    <div>
      <div
        css={css`
          display: flex;
          background-color: ${colors[backgroundColor]};
          ${mobile} {
            display: block;
          }
        `}
      >
        <div
          css={css`
            flex: 1;
          `}
        >
          <Section
            background={backgroundColor ? backgroundColor : "red"}
            noReveal={noReveal}
          >
            {superTitle && <H4>{superTitle}</H4>}
            <H1>{title}</H1>

            <Body
              dangerouslySetInnerHTML={{
                __html: bodyNode.childMarkdownRemark.html,
              }}
            />
            {linkText && linkUrl && (
              <Button>
                <a href={locale == "nl" ? linkUrl : `/en${linkUrl}`}>
                  {linkText}
                </a>
              </Button>
            )}
          </Section>
        </div>
        <div
          css={css`
            flex: 1;
            ${mobile} {
              margin-bottom: 32px;
            }
          `}
        >
          <BackgroundImage
            css={css`
              width: 100%;
              height: 100%;
              ${mobile} {
                display: none;
              }
            `}
            fluid={image.fluid}
          />
          <Img
            fluid={image.fluid}
            css={css`
              display: block;
              ${desktop} {
                display: none;
              }
            `}
          />
        </div>
      </div>
    </div>
  )
}

export const textImageBlockQuery = graphql`
  fragment TextImageBlockFragment on DatoCmsTextImageBlock {
    model {
      name
    }
    id
    bodyNode {
      childMarkdownRemark {
        html
      }
    }
    linkText
    linkUrl
    title
    superTitle
    backgroundColor
    image {
      fluid(maxWidth: 950, imgixParams: { fm: "png", auto: "compress" }) {
        base64
        aspectRatio
        src
        srcSet
        sizes
      }
    }
  }
`
