import React, { useEffect } from "react"
import { useLoader } from "react-three-fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

export function Model({ fadeInOnLoad, ...props }) {
  useEffect(() => {
    fadeInOnLoad()
  }, [])
  const gltf = useLoader(GLTFLoader, "/models/gk_glb_01-06-2021.glb")
  gltf.scene.traverse(function (node) {
    if (node.isMesh) {
      node.castShadow = true
      node.recieveShadow = true
    }
  })

  return <primitive object={gltf.scene} {...props} />
}
