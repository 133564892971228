export const zoomPointData = [
  {
    position: [5, 10.5, -15],
    id: 1,
    bgColor: "#82ABC0",
  },
  {
    position: [7, 6, 6],
    id: 2,
    bgColor: "#C18A88",
  },
  {
    position: [7, 0, -2],
    id: 3,
    bgColor: "#632C2B",
  },
  {
    position: [0, 2, -13],
    id: 4,
    bgColor: "#82ABC0",
  },
  {
    position: [-4, 3, 7],
    id: 5,
    bgColor: "#C18A88",
  },
  {
    position: [-7, 0, -2],
    id: 6,
    bgColor: "#632C2B",
  },
  {
    position: [0, 11.5, 10],
    id: 7,
    bgColor: "#82ABC0",
  },
  {
    position: [0, 16, 3],
    id: 8,
    bgColor: "#C18A88",
  },
  {
    position: [0, 12, -13],
    id: 9,
    bgColor: "#632C2B",
  },
]
