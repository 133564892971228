/** @jsx jsx */

import { jsx } from "@emotion/react"
import { graphql, StaticQuery } from "gatsby"
import HeaderBase from "./header-base"

const Header: React.FC<{
  useTransparentHeader?: boolean
  headerColor?: Color
}> = ({ useTransparentHeader, headerColor }) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        datoCmsSiteConfig(locale: { eq: "nl" }) {
          headerTitle
          homeUrl
          enableMenuOnderzoek
          menuOnderzoek {
            title
            url
          }
          ticketsUrl
          ticketsButtonText
          enableMenuTentoonstelling
          menuTentoonstelling {
            title
            url
          }
          enableMenuDesktop
          menuDesktop {
            title
            url
          }
        }
      }
    `}
    render={HeaderBase({ useTransparentHeader, headerColor, locale: "nl" })}
  />
)
export default Header
