/* @jsx jsx */

import React from "react"
import { css, jsx } from "@emotion/react"

const baseStyle = `
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    height: 48%;
    width: 90%;
    left: 5%;
    top: 30px;
    z-index: 0;
    pointer-events: none;
    transition: opacity 1s;
  `

export function BgLetters({
  showFillLetters,
  showFadeLetters,
  fadeBgLetters,
  locale,
}) {
  return (
    <div
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
      `}
    >
      <div
        className={showFillLetters ? (fadeBgLetters ? "fade" : "show") : "hide"}
        css={css`
          ${baseStyle}
          background-image: ${locale == "nl"
            ? "url(/svg/degoudenstretched.svg)"
            : "url(/svg/thegoldenstretched.svg)"};
          background-position: center bottom;
          z-index: -2;
        `}
      ></div>

      <div
        className={showFadeLetters ? (fadeBgLetters ? "fade" : "show") : "hide"}
        css={css`
          ${baseStyle}
          background-image: ${locale == "nl"
            ? "url(/svg/degoudenstretchedfade.svg)"
            : "url(/svg/thegoldenstretchedfade.svg)"};
          background-position: center bottom;
          z-index: -2;
        `}
      ></div>
      <div
        className={
          showFadeLetters || showFillLetters
            ? fadeBgLetters
              ? "fade"
              : "show"
            : "hide"
        }
        css={css`
          ${baseStyle}
          background-image:  ${locale == "nl"
            ? "url(/svg/koetsstretched.svg)"
            : "url(/svg/coachstretched.svg)"};
          background-position: center top;
          top: 50%;
        `}
      ></div>
    </div>
  )
}
