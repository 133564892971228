import React from "react"
import { Point } from "./point"

export function PointManager({
  zoomPoints,
  setPointHovering,
  zoomInAnimation,
}) {
  return zoomPoints.map((point, i) => {
    return (
      <Point
        key={i}
        index={i}
        position={zoomPoints[i].position}
        setPointHovering={setPointHovering}
        zoomInAnimation={zoomInAnimation}
      />
    )
  })
}
