/** @jsx jsx */

import { css, jsx } from "@emotion/react"
import { Body, H5, Section } from "@src/components"
import { Column, Row } from "@src/components/grid"
import { graphql } from "gatsby"

export function Quote(
  quote1: any,
  name1: any,
  quote2: any,
  name2: any,
  backgroundColor: Color,
  noReveal?: boolean
) {
  return (
    <Section
      background={backgroundColor ? backgroundColor : "black"}
      noReveal={noReveal}
    >
      <Row>
        <Column>
          <H5
            css={css`
              text-align: center;
            `}
          >
            {quote1}
          </H5>
          <Body
            css={css`
              text-align: center;
            `}
          >
            {name1}
          </Body>
        </Column>
        <Column className="hide-mobile">
          <H5
            css={css`
              text-align: center;
            `}
          >
            {quote2}
          </H5>
          <Body
            css={css`
              text-align: center;
            `}
          >
            {name2}
          </Body>
        </Column>
      </Row>
    </Section>
  )
}

export const quoteQuery = graphql`
  fragment QuoteFragment on DatoCmsQuote {
    model {
      name
    }
    backgroundColor
    id
    name1
    name2
    quote1
    quote2
  }
`
