/** @jsx jsx */

import { jsx, css } from "@emotion/react"
import BackgroundImage from "gatsby-background-image"
import { Section, H1, Button, Body, Column, CTA, Row } from "@src/components"
import { graphql } from "gatsby"
import { desktop, mobile } from "@src/style"
import Img from "gatsby-image"

type Props = {
  bodyNode: { childMarkdownRemark: { html: string } }
  linkText: string
  linkUrl: string
  image: any
  backgroundColor: Color
  locale: string
  noReveal?: boolean
}

export const TextImageBlockLeft: React.FC<Props> = ({
  bodyNode,
  linkText,
  linkUrl,
  image,
  backgroundColor = "black",
  locale,
  noReveal,
}) => {
  return (
    <Section
      id="intro"
      background={backgroundColor ? backgroundColor : "black"}
      noReveal={noReveal}
    >
      <Row>
        <Column
          css={css`
            ${mobile} {
              display: flex;
              justify-content: center;
            }
          `}
        >
          {image && image.fluid && (
            <Img
              css={css`
                width: 68%;
                display: block;
                margin-bottom: 42px;
                ${desktop} {
                  margin-bottom: 0;
                }
              `}
              // @ts-ignore
              fluid={image!.fluid!}
            />
          )}
        </Column>
        <Column
          css={css`
            ${desktop} {
              flex: 2;
            }
          `}
        >
          {bodyNode && (
            <Body
              dangerouslySetInnerHTML={{
                __html: bodyNode.childMarkdownRemark!.html!,
              }}
            />
          )}
          {linkText && linkUrl && (
            <CTA href={locale == "nl" ? linkUrl : `/en${linkUrl}`}>
              <span>{linkText}</span>
            </CTA>
          )}
        </Column>
      </Row>
    </Section>
  )
}

export const textImageBlockLeftQuery = graphql`
  fragment TextImageBlockLeftFragment on DatoCmsTextImageBlockLeft {
    model {
      name
    }
    id
    bodyNode {
      childMarkdownRemark {
        html
      }
    }
    linkText
    linkUrl
    backgroundColor
    image {
      fluid(maxWidth: 950, imgixParams: { fm: "png", auto: "compress" }) {
        base64
        aspectRatio
        src
        srcSet
        sizes
      }
    }
  }
`
