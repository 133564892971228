/* @jsx jsx */

import React from "react"
import { css, jsx } from "@emotion/react"
import Img from "gatsby-image"

export function ZoomScreenMobile({
  title,
  text,
  visible,
  zoomInAnimation,
  currentZoomPoint,
  amountOfPoints,
  youtubeLink,
  leesMeerLink,
  setUserZoom,
  setUserClicked,
  backToMain,
  userZoom,
  width,
  fluid,
  locale,
}) {
  console.log(text)
  let nextZoomPoint = currentZoomPoint + 1
  if (nextZoomPoint > amountOfPoints - 1) {
    nextZoomPoint = 0
  }
  let prevZoomPoint = currentZoomPoint - 1
  if (prevZoomPoint < 0) {
    prevZoomPoint = amountOfPoints - 1
  }
  return (
    <div
      className={visible ? "show" : "hide"}
      css={css`
        width: 100%;
        max-width: 500px;
        height: 85%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        padding-left: 10px;
        padding-right: 10px;
        overflow-y: auto;
        font-family: "GT Flexa Trial";
        font-size: 1rem;
      `}
      style={userZoom ? { pointerEvents: "auto" } : { pointerEvents: "none" }}
    >
      <div
        css={css`
          height: 30%;
          margin-top: 5%;
          width: 80%;
          pointer-events: none;
          margin: auto;
        `}
      >
        <Img
          fluid={fluid}
          css={css`
            object-position: center center;
            z-index: -10;
            margin-top: 5%;
          `}
        />
      </div>

      {/* PAGE COUNTER */}
      <div
        css={css`
          font-family: "Everett-Light";
          font-size: 1rem;
        `}
      >
        {currentZoomPoint + 1}/{amountOfPoints}
      </div>

      {/* CONTENT */}
      <h1
        css={css`
          margin-top: 0;
          text-transform: uppercase;
        `}
      >
        {title}
      </h1>

      <p
        css={css`
          font-family: "Everett-Light";
          max-height: 20%;
          overflow-y: scroll;
        `}
        dangerouslySetInnerHTML={{
          __html: text.childMarkdownRemark!.html!,
        }}
      ></p>
      {youtubeLink && (
        <p>
          {" "}
          <a
            href={youtubeLink}
            css={css`
          font-family: "Everett-Light";
          background-color: #1e3e47;
          height: 31px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          width: auto;
          line-height: 31px;
          text-decoration: none;
          display: inline-block;
          padding: 0 30px 0 30px;
          &:hover{
            background-color: white;
            color: #1e3e47;
            border 1px solid #1e3e47;
          }
        `}
          >
            {locale == "nl" ? "Bekijk video" : "Watch video"}
          </a>
        </p>
      )}
      {leesMeerLink && (
        <p>
          {" "}
          <a
            css={css`
        background-color: #1e3e47;
        font-family: "Everett-Light";
        height: 31px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        width: auto;
        line-height: 31px;
        text-decoration: none;
        display: inline-block;
        padding: 0 30px 0 30px;
        &:hover{
          background-color: white;
          color: #1e3e47;
          border 1px solid #1e3e47;
        }
      `}
            href={leesMeerLink}
          >
            {locale == "nl" ? "Verder lezen →" : "Read more →"}
          </a>
        </p>
      )}

      {/* TERUG NAAR DE KOETS + VOLGENDE + VORIGE KNOP */}
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          z-index: 1;
          text-transform: uppercase;
          font-family: "Snv Cond D Fixed";
          font-size: 1.5rem;
          margin-top: 10px;
          position: absolute;
          bottom: 0;
          left: 0;
        `}
      >
        {/* VORIGE */}
        <div
          css={css`
            display: flex;
            cursor: pointer;
            &:hover {
              font-style: italic;
            }
          `}
          onClick={() => zoomInAnimation(prevZoomPoint)}
        >
          {" "}
          <div
            css={css`
              background-image: url("/svg/arrowleftwhite.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              height: 1.5rem;
              width: 1.5rem;
              margin-right: 5px;
            `}
          ></div>
          {locale == "nl" ? "VORIGE" : "PREVIOUS"}
        </div>

        {/* VOLGENDE */}
        <div
          css={css`
            display: flex;
            cursor: pointer;
            &:hover {
              font-style: italic;
            }
          `}
          onClick={() => zoomInAnimation(nextZoomPoint)}
        >
          {locale == "nl" ? "VOLGENDE" : "NEXT"}

          <div
            css={css`
              background-image: url("/svg/arrowrightwhite.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              height: 1.5rem;
              width: 1.5rem;
              margin-left: 5px;
            `}
          ></div>
        </div>
        {/* TERUG NAAR KOETS */}
        <div
          css={css`
            display: flex;
            cursor: pointer;
            width: 100%;
            margin-top: 15px;
            &:hover {
              font-style: italic;
            }
          `}
          onClick={() => {
            setUserZoom(false)
            backToMain()
            setTimeout(() => setUserClicked(false), 100)
          }}
        >
          <div
            css={css`
              background-image: url("/svg/arrowleftwhitedouble.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              height: 1.5rem;
              width: 1.5rem;
              margin-right: 5px;
            `}
          ></div>
          {locale == "nl" ? "terug naar de koets" : "back to the coach"}
        </div>
      </div>
    </div>
  )
}
