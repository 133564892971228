/* @jsx jsx */

import React from "react"
import { css, jsx } from "@emotion/react"
import { mobileBreakPoint } from "./3dkoetsApp"
import { ZoomScreenDesktop } from "./zoomScreenDesktop"
import { ZoomScreenMobile } from "./zoomScreenMobile"

export function ZoomScreen({ width, ...props }) {
  return width > mobileBreakPoint ? (
    <ZoomScreenDesktop {...props} />
  ) : (
    <ZoomScreenMobile {...props} />
  )
}
