/** @jsx jsx */

import { css, jsx } from "@emotion/react"
import { IoMdClose } from "react-icons/io"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useState } from "react"
import { colors } from "@src/style"
import { H1 } from "./typography"

type Props = { onClose: () => void }

export const NewsletterOverlay: React.FC<Props> = ({ onClose }) => {
  const [email, setEmail] = useState<string>()
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false)
  const [result, setResult] = useState<string>()
  const onSubmit = e => {
    e.preventDefault()
    // console.log({ email, firstName, lastName, acceptedTerms })
    if (acceptedTerms) {
      addToMailchimp(email, { FNAME: firstName, LNAME: lastName })
        .then(response => setResult(response.msg))
        .catch(response => setResult(response.msg))
    } else {
      setResult("je moet nog aanvinken dat je de nieuwsbrief wilt ontvangen.")
    }
    return false
  }
  return (
    <div
      id="mc_embed_signup"
      css={css`
        position: fixed;
        z-index: 1000;
        top: 100px;
        bottom: 100px;
        width: 500px;
        max-width: 90%;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
        padding: 32px;
        overflow-y: auto;
        font-family: Everett;
      `}
    >
      <div
        css={css`
          text-align: right;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 50px;
          padding: 16px;
        `}
      >
        <IoMdClose
          onClick={onClose}
          css={css`
            cursor: pointer;
          `}
        />
      </div>
      <form
        css={css`
          position: absolute;
          top: 50px;
          left: 0;
          bottom: 0;
          overflow-y: auto;
          padding: 0 32px 32px;
        `}
      >
        <div>
          <H1>Nieuwsbrief</H1>
          <div>
            <label htmlFor="mce-FNAME">
              Voornaam <span>*</span>
            </label>
            <input
              type="text"
              css={css`
                display: block;
                width: 100%;
                margin-bottom: 8px;
              `}
              id="mce-FNAME"
              onChange={e => setFirstName(e.target.value)}
              value={firstName}
            />
          </div>
          <div>
            <label htmlFor="mce-LNAME">Achternaam </label>
            <input
              type="text"
              css={css`
                display: block;
                width: 100%;
                margin-bottom: 8px;
              `}
              id="mce-LNAME"
              onChange={e => setLastName(e.target.value)}
              value={lastName}
            />
          </div>
          <div>
            <label htmlFor="mce-EMAIL">
              E-mailadres <span>*</span>
            </label>
            <input
              type="email"
              css={css`
                display: block;
                width: 100%;
                margin-bottom: 8px;
              `}
              id="mce-EMAIL"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div>
            <div>
              <label>Toestemmingen</label>
              <p>
                Het Amsterdam Museum gebruikt de gegevens die je op dit
                formulier verstrekt om je een keer per maand de nieuwsbrief toe
                te sturen.
              </p>
              <label htmlFor="gdpr_1">
                <input
                  id="gdpr_1"
                  type="checkbox"
                  checked={acceptedTerms}
                  onChange={e => setAcceptedTerms(e.target.value)}
                  css={css`
                    margin-right: 8px;
                  `}
                />
                <span>Ja, ik ontvang graag de nieuwsbrief per e-mail.</span>
              </label>
              <p>
                Je kunt je op elk moment uitschrijven door op de link 'Schrijf
                je uit voor de nieuwsbrief' te klikken in de voettekst van elke
                e-mail die je van ons ontvangt, of door contact op te nemen via
                communicatie@amsterdammuseum.nl. Wij geven zonder toestemming
                geen e-mailadressen aan derden. Door hieronder te klikken, stem
                je ermee in dat wij uw gegevens mogen verwerken in
                overeenstemming met deze voorwaarden. Bezoek onze website voor
                meer informatie over onze privacypraktijken.
              </p>
            </div>
            <div>
              <p>
                We use Mailchimp as our marketing platform. By clicking below to
                subscribe, you acknowledge that your information will be
                transferred to Mailchimp for processing.
                <a href="https://mailchimp.com/legal/" target="_blank">
                  Learn more about Mailchimp's privacy practices here.
                </a>
              </p>
            </div>
          </div>
          <div>
            <div style={{ display: "none" }}></div>
            <div style={{ display: "none" }}></div>
          </div>
          <div style={{ position: "absolute", left: -5000 }} aria-hidden="true">
            <input type="text" tabIndex={-1} value="" />
          </div>
          <div className="clear">
            <input
              css={css`
                background: ${colors.black};
                color: ${colors.white};
                padding: 12px;
                font-family: Everett;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: center;
                height: 42px;
                min-width: 120px;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 1px solid;
                border-radius: 21px;
                &:hover {
                  color: ${colors.white};
                  background-color: ${colors.offBlack};
                }
              `}
              type="submit"
              value="Inschrijven"
              name="subscribe"
              id="mc-embedded-subscribe"
              onClick={onSubmit}
            />
          </div>
        </div>
      </form>
      {result && (
        <div
          css={css`
            color: ${colors.red};
            margin-top: 32px;
          `}
        >
          {result}
        </div>
      )}
    </div>
  )
}
