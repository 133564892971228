import React, { useState } from "react"
import { useFrame } from "react-three-fiber"

const angleOffset = 1.2
const lightDist = 30

export function Lights({ camData }) {
  const [xPos, setXpos] = useState()
  const [zPos, setZpos] = useState()

  //console.log(camData)
  useFrame(() => {
    let angle = camData.current.getAzimuthalAngle()
    setXpos(lightDist * Math.sin(angle + angleOffset))
    setZpos(lightDist * Math.cos(angle + angleOffset))
  })

  return (
    <group>
      <ambientLight intensity={0.4} />

      {/* <mesh position={[xPos, 20, zPos]}>
        <boxBufferGeometry attach="geometry" args={[1, 6]} />
        <meshBasicMaterial attach="material" color="white" />
      </mesh> */}

      <pointLight
        //castShadow
        position={[xPos, 40, zPos]}
        intensity={1.8}
        shadow-radius={4}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={100}
      />
    </group>
  )
}
