/** @jsx jsx */

import { jsx, css } from "@emotion/react"
import BackgroundImage from "gatsby-background-image"
import { desktop } from "@src/style"
import { graphql } from "gatsby"

export function Banner(image: any) {
  return (
    <BackgroundImage
      css={css`
        height: 50vh;
        ${desktop} {
          height: 60vh;
        }
      `}
      fluid={image.fluid}
    />
  )
}

export const bannerQuery = graphql`
  fragment BannerFragment on DatoCmsBanner {
    model {
      name
    }
    id
    image {
      fluid(maxWidth: 1900, imgixParams: { fm: "jpg", auto: "compress" }) {
        base64
        aspectRatio
        src
        srcSet
        sizes
      }
    }
  }
`
