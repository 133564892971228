import React from "react"
import styled from "@emotion/styled"
import { colors, desktop } from "@src/style"
import Fade from "react-reveal/Fade"
import { SerializedStyles } from "@emotion/react"

type SectionProps = {
  background: Color
  css?: SerializedStyles
  noReveal?: boolean
}

const StyledSection = styled.section<SectionProps>`
  padding: 84px 30px 76px 30px;
  background: ${props => colors[props.background]};
  color: ${props =>
    props.background === "black" ||
    props.background === "red" ||
    props.background === "blue" ||
    props.background === "steelBlue" ||
    props.background === "gold" ||
    props.background === "oldRose" ||
    props.background === "green"
      ? colors.white
      : colors.black};
  text-align: center;
  overflow-x: hidden;
  h1 {
    font-family: GT Flexa Trial;
    font-size: 36px;
    font-weight: normal;
    margin-top: 50px;
  }
  ${desktop} {
    text-align: left;
    padding: 110px 12% 90px;
  }
`
export const Section: React.FC<SectionProps> = props =>
  props.noReveal ? (
    <StyledSection {...props} />
  ) : (
    <Fade bottom duration={200} distance="100px">
      <StyledSection {...props} />
    </Fade>
  )
