import React from "react"
import styled from "@emotion/styled"
import { desktop } from "@src/style"

export const Row = styled.div`
  ${desktop} {
    display: flex;
    margin: 0 -12px;
  }
`
export const Column = styled.div<{ fixed?: boolean }>`
  ${desktop} {
    flex: ${props => (props.fixed ? "0 0 424px" : "1 1 12px")};
    margin: 0 12px;
  }
`
