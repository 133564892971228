/** @jsx jsx */

import { jsx, css } from "@emotion/react"
import Img from "gatsby-image"
import { desktop, mobile } from "@src/style"
import { Column, Row } from "@src/components/grid"
import { graphql } from "gatsby"

export function DoubleImage(image1: any, image2: any) {
  return (
    <div
      css={css`
        ${desktop} {
          padding: 0 50px;
        }
      `}
    >
      <Row>
        <Column
          css={css`
            ${mobile} {
              margin: 0 30px;
            }
          `}
        >
          <Img
            fluid={image1.fluid}
            css={css`
              width: 100%;
              ${mobile} {
                margin-bottom: 16px;
              }
            `}
          />
        </Column>
        <Column
          css={css`
            ${mobile} {
              margin: 0 30px;
            }
          `}
        >
          <Img
            fluid={image2.fluid}
            css={css`
              width: 100%;
            `}
          />
        </Column>
      </Row>
    </div>
  )
}

export const doubleImageQuery = graphql`
  fragment DoubleImageFragment on DatoCmsDoubleImage {
    model {
      name
    }
    id
    image1 {
      fluid(maxWidth: 950, imgixParams: { fm: "jpg", auto: "compress" }) {
        base64
        aspectRatio
        src
        srcSet
        sizes
      }
    }
    image2 {
      fluid(maxWidth: 950, imgixParams: { fm: "jpg", auto: "compress" }) {
        base64
        aspectRatio
        src
        srcSet
        sizes
      }
    }
  }
`
