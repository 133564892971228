/** @jsx jsx */

import { css, jsx } from "@emotion/react"
import { footerLogo, partners } from "@src/assets/images"
import { colors, desktop, mobile } from "@src/style"
import { GlobalStyles } from "@src/style/global"
import { graphql, StaticQuery } from "gatsby"
import "normalize.css"
import PropTypes from "prop-types"
import React, { Fragment, useState } from "react"
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"
import { Body, Button, H1, Section } from "."
import { CTA } from "./buttons"
import { Column, Row } from "./grid"
import Header from "./header"
import { NewsletterOverlay } from "./newsletter-overlay"
import WebFont from "webfontloader"
import SEO from "./seo"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

type Props = {
  useTransparentHeader?: boolean
  headerColor?: Color
}

const Layout: React.FC<Props> = ({
  children,
  useTransparentHeader,
  headerColor,
}) => {
  const [showNewsletterOverlay, setShowNewsletterOverlay] = useState(false)
  const location = useLocation()
  // console.log("layoutnl", { headerColor })
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSiteConfig {
            facebookUrl
            faqUrl
            twitterUrl
            voorwaardenUrl
            instagramUrl
            privacyUrl
            newsletterUrl
          }
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Fragment>
          <SEO />
          <GlobalStyles />
          <CookieConsent
            enableDeclineButton
            buttonText="Accepteren"
            declineButtonText="Weigeren"
            onAccept={() => {
              Cookies.set("gatsby-gdpr-google-tagmanager", true)
              Cookies.set("gatsby-gdpr-facebook-pixel", true)
              initializeAndTrack(location)
            }}
            style={{
              background: colors.black,
              fontFamily: "Everett",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "18px",
              letterSpacing: "0px",
            }}
            buttonStyle={{
              padding: "16px",
              background: colors.gold,
              color: colors.white,
            }}
            declineButtonStyle={{
              padding: "16px",
              background: colors.red,
              color: colors.white,
            }}
          >
            Deze website maakt gebruik van cookies
          </CookieConsent>
          <Header
            useTransparentHeader={useTransparentHeader}
            siteTitle={data.site.siteMetadata?.title || `Gouden Koets`}
            headerColor={headerColor}
          />
          <div
            id="top"
            css={css`
              margin: ${useTransparentHeader ? "0 auto" : "80px auto 0"};
              width: 100%;
              ${desktop} {
                margin: ${useTransparentHeader ? "0 auto" : "80px auto 0"};
              }
            `}
          >
            <main>{children}</main>
            <footer>
              <Section
                background="gold"
                css={css`
                  ${desktop} {
                    text-align: center;
                  }
                `}
              >
                <H1>
                  Nieuws over onze Gouden Koets-tentoonstelling ontvangen?
                </H1>
                <Body>Schrijf je dan in voor onze nieuwsbrief</Body>
                <Button onClick={() => setShowNewsletterOverlay(true)}>
                  Schrijf je nu in
                </Button>
              </Section>
              {showNewsletterOverlay && (
                <NewsletterOverlay
                  onClose={() => setShowNewsletterOverlay(false)}
                />
              )}
              <Section
                background="black"
                css={css`
                  text-align: left;
                  z-index: 999;
                  position: relative;
                `}
                noReveal
              >
                <Row>
                  <Column>
                    <img
                      src={footerLogo}
                      css={css`
                        width: 59px;
                        margin-bottom: 64px;
                        ${desktop} {
                          width: 197px;
                          margin-bottom: 0;
                        }
                      `}
                    />
                  </Column>
                  <Column
                    css={css`
                      ${desktop} {
                        flex: 2;
                      }
                    `}
                  >
                    <Body>
                      Amsterdam Museum
                      <br />
                      Kalverstraat 92
                      <br />
                      1012 PH Amsterdam
                      <br />
                      <br />
                      020 5231 822
                      <br />
                      info@goudenkoets.nl
                    </Body>
                  </Column>
                  <Column
                    css={css`
                      ${mobile} {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 50px;
                      }
                      ${desktop} {
                        text-align: right;
                      }
                    `}
                  >
                    <div
                      css={css`
                        font-size: 25px;
                      `}
                      className="hide-desktop"
                    >
                      <a
                        css={css`
                          display: inline-block;
                          margin-right: 13px;
                        `}
                        href={data.datoCmsSiteConfig.facebookUrl}
                      >
                        <FaFacebookF />
                      </a>{" "}
                      <a
                        css={css`
                          display: inline-block;
                          margin-right: 13px;
                        `}
                        href={data.datoCmsSiteConfig.twitterUrl}
                      >
                        <FaTwitter />
                      </a>{" "}
                      <a
                        css={css`
                          display: inline-block;
                        `}
                        href={data.datoCmsSiteConfig.instagramUrl}
                      >
                        <FaInstagram />
                      </a>
                    </div>
                    <CTA
                      direction="up"
                      css={css`
                        margin-bottom: 0;
                      `}
                      onClick={() =>
                        document
                          .getElementById("top")!
                          .scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Terug naar boven
                    </CTA>
                  </Column>
                </Row>
                <Row>
                  <Column></Column>
                  <Column
                    css={css`
                      ${desktop} {
                        flex: 2;
                      }
                    `}
                  >
                    <Body>
                      <a
                        href={data.datoCmsSiteConfig.privacyUrl}
                        css={css`
                          color: ${colors.grey};
                          text-decoration: none;
                          display: inline-block;
                          margin-right: 44px;
                        `}
                      >
                        Privacy
                      </a>
                      <a
                        href={data.datoCmsSiteConfig.voorwaardenUrl}
                        css={css`
                          color: ${colors.grey};
                          text-decoration: none;
                          display: inline-block;
                          margin-right: 44px;
                        `}
                      >
                        Voorwaarden
                      </a>
                      <a
                        href={data.datoCmsSiteConfig.faqUrl}
                        css={css`
                          color: ${colors.grey};
                          text-decoration: none;
                          display: inline-block;
                          margin-right: 44px;
                        `}
                      >
                        FAQ
                      </a>
                    </Body>
                  </Column>
                  <Column
                    css={css`
                      ${desktop} {
                        text-align: right;
                      }
                      color: ${colors.grey};
                    `}
                  >
                    <Body className="hide-mobile">
                      <a
                        css={css`
                          display: inline-block;
                          margin-right: 35px;
                        `}
                        href={data.datoCmsSiteConfig.facebookUrl}
                      >
                        <FaFacebookF />
                      </a>{" "}
                      <a
                        css={css`
                          display: inline-block;
                          margin-right: 35px;
                        `}
                        href={data.datoCmsSiteConfig.twitterUrl}
                      >
                        <FaTwitter />
                      </a>{" "}
                      <a
                        css={css`
                          display: inline-block;
                        `}
                        href={data.datoCmsSiteConfig.instagramUrl}
                      >
                        <FaInstagram />
                      </a>
                    </Body>
                  </Column>
                </Row>
                <Row>
                  <img
                    css={css`
                      width: 100%;
                    `}
                    src={partners}
                    alt="partners"
                  />
                </Row>
              </Section>
            </footer>
          </div>
        </Fragment>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
