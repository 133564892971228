/* @jsx jsx */

import React from "react"
import { css, jsx } from "@emotion/react"
import Img from "gatsby-image"

export function ZoomImg({ fluid, imgVisible, overlayVisible, bgColor }) {
  let colorArr = extractBgColors(bgColor)
  return (
    <div
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        pointer-events: none;
      `}
    >
      <div
        className={imgVisible ? "show" : "hide"}
        css={css`
          height: 100%;
          width: 60%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          pointer-events: none;
          transition: opacity 1s;
        `}
      >
        {fluid && (
          <Img
            fluid={fluid}
            css={css`
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              object-position: center center;
            `}
          />
        )}
      </div>
      <div
        className={overlayVisible ? "show" : "hide"}
        css={css`
          background: linear-gradient(
            90deg,
            rgba(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]}, 0) 40%,
            rgba(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]}, 0.8) 60%
          );
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          pointer-events: none;
          transition: opacity 1s;
        `}
        style={{ background: "" }}
      ></div>
    </div>
  )
}

function extractBgColors(color) {
  return [
    parseInt(color.slice(1, 3), 16),
    parseInt(color.slice(3, 5), 16),
    parseInt(color.slice(5, 7), 16),
  ]
}
