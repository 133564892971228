/* @jsx jsx */

import { jsx, css } from "@emotion/react"
import { colors, desktop, mobile } from "@src/style"
import { Link } from "gatsby"
import { amsterdamMuseumLogo, logoBlack } from "@src/assets/images"
import { Button } from "./buttons"

export type NavBarProps = {
  transparentNav: boolean | undefined
  showNav: boolean
  showMenu: boolean
  headerTitle: string
  homeUrl: string
  enableMenuDesktop: boolean
  menuDesktop: any
  ticketsUrl: string
  ticketsButtonText: string
  setShowMenu: (showMenu: boolean) => void
  headerColor: Color
}
export const NavBar: React.FC<NavBarProps> = ({
  transparentNav,
  showNav,
  showMenu,
  headerTitle,
  homeUrl,
  enableMenuDesktop,
  menuDesktop,
  ticketsUrl,
  ticketsButtonText,
  setShowMenu,
  headerColor,
}) => {
  // console.log("heyhaheaye", { amsterdamMuseumLogo })
  return (
    <header
      css={css`
        background: ${headerColor
          ? colors[headerColor]
          : transparentNav
          ? "#00000000"
          : showMenu
          ? colors.green
          : colors.black};
        color: ${colors.white};
        min-height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: ${transparentNav ? "flex-start" : "center"};
        padding: ${transparentNav ? "30px" : "12px 30px"};
        position: fixed;
        top: 0;
        left: 50%;
        z-index: 1000;
        width: 100%;
        transition: all 0s ease-out, transform 0.2s ease-out;
        transform: ${showNav || showMenu
          ? "translate(-50%, 0px)"
          : "translate(-50%, -250px)"};
        height: 80px; //${showNav || showMenu ? 120 : 80}px;
        ${desktop} {
          padding: ${transparentNav ? "80px 30px 8px 60px" : "8px 30px"};
          transform: ${showNav || showMenu
            ? "translate(-50%, 0)"
            : "translate(-50%, -320px)"};
          margin: 0 -1px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          position: relative;
        `}
      >
        {!transparentNav && (
          <Link
            to={homeUrl}
            style={{
              color: `white`,
              textDecoration: `none`,
              transition: "none",
            }}
          >
            <img
              css={css`
                width: 147px;
                max-width: 15vw;
                margin-top: 80px;
                margin-left: 30px;
                transform: rotate(0deg);
                background: ${headerColor ? colors[headerColor] : colors.black};
                border: 20px solid
                  ${headerColor ? colors[headerColor] : colors.black};
                overflow: hidden;
                border-radius: 50%;
                &:hover {
                  transform: rotate(128deg);
                }
                ${mobile} {
                  margin-top: 0;
                  margin-left: 0;
                  width: 50px;
                  border: none;
                }
              `}
              src={headerColor === "white" ? logoBlack : amsterdamMuseumLogo}
            />
          </Link>
        )}
      </div>

      {enableMenuDesktop && !transparentNav && (
        <div
          css={css`
            flex: 1;
            display: flex;
            justify-content: flex-end;
            margin-right: 32px;
          `}
        >
          {menuDesktop.map((link, i) => (
            <div
              css={css`
                margin: 0 10px;
                min-width: 0px;
                width: 100px;
                background: transparent;
                color: ${headerColor === "white" ? colors.black : colors.white};
                font-family: GT Flexa Trial;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 36px;
                letter-spacing: 0em;
                margin: 0px;
                text-align: center;

                &:hover {
                  background: ${colors.white};
                  color: ${colors.black};
                }
              `}
              key={`menu-desktop-${i}`}
            >
              <a
                css={css`
                  text-decoration: none;
                  text-transform: uppercase;
                `}
                href={link.url}
              >
                {link.title}
              </a>
            </div>
          ))}
        </div>
      )}
      <div
        css={css`
          display: flex;
          align-items: center;
          ${desktop} {
            margin-top: ${transparentNav ? "-40px" : "0px"};
          }
        `}
      >
        <span
          css={css`
            cursor: pointer;
            position: relative;
            margin-left: ${transparentNav ? "10px" : "63px"};
            width: 44px;
            height: 16px;
            overflow: hidden;
            ${desktop} {
              margin-left: 33px;
            }
          `}
          onClick={() => setShowMenu(!showMenu)}
        >
          <div
            css={css`
              height: 0;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0px;
              border-top: 2px solid
                ${headerColor === "white" ? colors.black : colors.white};
            `}
          />
          <div
            css={css`
              height: 0;
              width: 100%;
              position: absolute;
              bottom: 0;
              left: 22px;
              border-top: 2px solid
                ${headerColor === "white" ? colors.black : colors.white};
              *:hover > & {
                left: 0px;
              }
            `}
          />
        </span>
      </div>
    </header>
  )
}
