import styled from "@emotion/styled"
import { desktop } from "@src/style"

export const H1 = styled.div`
  //styleName: Desktop / H1;
  font-family: GT Flexa Trial;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0px;

  text-transform: uppercase;
  margin-bottom: 30px;

  ${desktop} {
    font-size: 75px;
    line-height: 75px;
  }
`
export const H2 = styled.div`
  //styleName: Mobile / H2;
  font-family: GT Flexa Trial;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;

  text-transform: uppercase;

  ${desktop} {
    font-size: 54px;
    line-height: 54px;
  }
`
export const H3 = styled.div`
  //styleName: Mobile / H3;
  font-family: GT Flexa Trial;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;

  text-transform: uppercase;

  ${desktop} {
    font-size: 42px;
    line-height: 42px;
  }
`

export const H4 = styled.div`
  //styleName: Mobile / H4;
  font-family: GT Flexa Trial;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;

  text-transform: uppercase;
  margin-bottom: 8px;

  ${desktop} {
    font-size: 36px;
    line-height: 36px;
  }
`

export const H5 = styled.div`
  //styleName: Mobile / H5;
  font-family: Everett;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;

  ${desktop} {
    font-size: 24px;
    line-height: 28px;
  }
`

export const H6 = styled.div`
  //styleName: Mobile / H6;
  font-family: Everett;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;

  ${desktop} {
    font-size: 14px;
    line-height: 18px;
  }
`

export const Body = styled.div`
  //styleName: Mobile / Body;
  font-family: Everett-Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;

  margin-bottom: 60px;

  ${desktop} {
    font-size: 18px;
    line-height: 28px;
  }

  & > p:first-of-type {
    margin-top: 0;
  }
`
