/** @jsx jsx */

import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, StaticQuery } from "gatsby"
import { css, jsx } from "@emotion/react"
import { Helmet } from "react-helmet"

import Header from "./header-en"
import "normalize.css"
import { GlobalStyles } from "@src/style/global"
import { Section, H1, Body, Button } from "."
import { Column, Row } from "./grid"
import { footerLogo, partners } from "@src/assets/images"
import { colors, desktop, mobile } from "@src/style"
import { LinkButton } from "./buttons"
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa"
import { NewsletterOverlay } from "./newsletter-overlay"
import SEO from "./seo"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

type Props = {
  useTransparentHeader?: boolean
  headerColor?: Color
}

const LayoutEn: React.FC<Props> = ({
  children,
  useTransparentHeader,
  headerColor,
}) => {
  const [showNewsletterOverlay, setShowNewsletterOverlay] = useState(false)
  const location = useLocation()
  return (
    <StaticQuery
      query={graphql`
        query LayoutEnQuery {
          datoCmsSiteConfig(locale: { eq: "en" }) {
            facebookUrl
            faqUrl
            twitterUrl
            voorwaardenUrl
            instagramUrl
            privacyUrl
            newsletterUrl
            newsletterTitle
            newsletterBody
            newsletterButton
            scrollToTop
            privacyLabel
            termsLabel
            faqLabel
          }
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Fragment>
          <SEO />
          <GlobalStyles />
          <CookieConsent
            enableDeclineButton
            buttonText="Accept"
            declineButtonText="Decline"
            onAccept={() => {
              Cookies.set("gatsby-gdpr-google-tagmanager", true)
              Cookies.set("gatsby-gdpr-facebook-pixel", true)
              initializeAndTrack(location)
            }}
            style={{
              background: colors.black,
              fontFamily: "Everett",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "18px",
              letterSpacing: "0px",
            }}
            buttonStyle={{
              padding: "16px",
              background: colors.gold,
              color: colors.white,
            }}
            declineButtonStyle={{
              padding: "16px",
              background: colors.red,
              color: colors.white,
            }}
          >
            This website uses cookies
          </CookieConsent>
          <Header
            useTransparentHeader={useTransparentHeader}
            siteTitle={data.site.siteMetadata?.title || `Gouden Koets`}
            headerColor={headerColor}
          />
          <div
            id="top"
            css={css`
              margin: ${useTransparentHeader ? "0 auto" : "80px auto 0"};
              width: 100%;
              ${desktop} {
                margin: ${useTransparentHeader ? "0 auto" : "80px auto 0"};
              }
            `}
          >
            <main>{children}</main>
            <footer>
              <Section background="gold">
                <H1>{data.datoCmsSiteConfig.newsletterTitle}</H1>
                <Body>{data.datoCmsSiteConfig.newsletterBody}</Body>
                <Button onClick={() => setShowNewsletterOverlay(true)}>
                  {data.datoCmsSiteConfig.newsletterButton}
                </Button>
              </Section>
              {showNewsletterOverlay && (
                <NewsletterOverlay
                  onClose={() => setShowNewsletterOverlay(false)}
                />
              )}
              <Section
                background="black"
                css={css`
                  text-align: left;
                  z-index: 999;
                  position: relative;
                `}
                noReveal
              >
                <Row>
                  <Column>
                    <img
                      src={footerLogo}
                      css={css`
                        width: 59px;
                        margin-bottom: 64px;
                        ${desktop} {
                          width: 197px;
                          margin-bottom: 0;
                        }
                      `}
                    />
                  </Column>
                  <Column
                    css={css`
                      ${desktop} {
                        flex: 2;
                      }
                    `}
                  >
                    <Body>
                      Amsterdam Museum
                      <br />
                      Kalverstraat 92
                      <br />
                      1012 PH Amsterdam
                      <br />
                      <br />
                      +31 20 5231 822
                      <br />
                      info@goudenkoets.nl
                    </Body>
                  </Column>
                  <Column
                    css={css`
                      ${mobile} {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 50px;
                      }
                      ${desktop} {
                        text-align: right;
                      }
                    `}
                  >
                    <div
                      css={css`
                        font-size: 25px;
                      `}
                      className="hide-desktop"
                    >
                      <a
                        css={css`
                          display: inline-block;
                          margin-right: 13px;
                        `}
                        href={data.datoCmsSiteConfig.facebookUrl}
                      >
                        <FaFacebookF />
                      </a>{" "}
                      <a
                        css={css`
                          display: inline-block;
                          margin-right: 13px;
                        `}
                        href={data.datoCmsSiteConfig.twitterUrl}
                      >
                        <FaTwitter />
                      </a>{" "}
                      <a
                        css={css`
                          display: inline-block;
                        `}
                        href={data.datoCmsSiteConfig.instagramUrl}
                      >
                        <FaInstagram />
                      </a>
                    </div>
                    <LinkButton
                      direction="up"
                      color="white"
                      hoverColor="red"
                      css={css`
                        margin-bottom: 0;
                      `}
                      onClick={() =>
                        document
                          .getElementById("top")!
                          .scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      {data.datoCmsSiteConfig.scrollToTop}
                    </LinkButton>
                  </Column>
                </Row>
                <Row>
                  <Column></Column>
                  <Column
                    css={css`
                      ${desktop} {
                        flex: 2;
                      }
                    `}
                  >
                    <Body>
                      <a
                        href={data.datoCmsSiteConfig.privacyUrl}
                        css={css`
                          color: ${colors.grey};
                          text-decoration: none;
                          display: inline-block;
                          margin-right: 44px;
                        `}
                      >
                        {data.datoCmsSiteConfig.privacyLabel}
                      </a>
                      <a
                        href={data.datoCmsSiteConfig.voorwaardenUrl}
                        css={css`
                          color: ${colors.grey};
                          text-decoration: none;
                          display: inline-block;
                          margin-right: 44px;
                        `}
                      >
                        {data.datoCmsSiteConfig.termsLabel}
                      </a>
                      <a
                        href={data.datoCmsSiteConfig.faqUrl}
                        css={css`
                          color: ${colors.grey};
                          text-decoration: none;
                          display: inline-block;
                          margin-right: 44px;
                        `}
                      >
                        {data.datoCmsSiteConfig.faqLabel}
                      </a>
                    </Body>
                  </Column>
                  <Column
                    css={css`
                      ${desktop} {
                        text-align: right;
                      }
                      color: ${colors.grey};
                    `}
                  >
                    <Body className="hide-mobile">
                      <a
                        css={css`
                          display: inline-block;
                          margin-right: 35px;
                        `}
                        href={data.datoCmsSiteConfig.facebookUrl}
                      >
                        <FaFacebookF />
                      </a>{" "}
                      <a
                        css={css`
                          display: inline-block;
                          margin-right: 35px;
                        `}
                        href={data.datoCmsSiteConfig.twitterUrl}
                      >
                        <FaTwitter />
                      </a>{" "}
                      <a
                        css={css`
                          display: inline-block;
                        `}
                        href={data.datoCmsSiteConfig.instagramUrl}
                      >
                        <FaInstagram />
                      </a>
                    </Body>
                  </Column>
                </Row>
                <Row>
                  <img
                    css={css`
                      width: 100%;
                    `}
                    src={partners}
                    alt="partners"
                  />
                </Row>
              </Section>
            </footer>
          </div>
        </Fragment>
      )}
    />
  )
}

LayoutEn.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutEn
