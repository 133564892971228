/* @jsx jsx */

import React, { useState, useRef } from "react"
import { useLoader, useFrame } from "react-three-fiber"
import { TextureLoader } from "three"
import { animated, useSpring } from "react-spring-three"
import { css, jsx } from "@emotion/react"
import { Html } from "@react-three/drei"

import point from "../../../assets/images/pointred.png"

export function Point({ position, index, setPointHovering, zoomInAnimation }) {
  const texture = useLoader(TextureLoader, point)
  const [hovered, setHovered] = useState(false)
  const [wobbleScale, setWobbleScale] = useState(1.5)
  const hoverAnimation = useSpring({
    config: { duration: 100 },
    scale: hovered ? [2.5, 2.5, 2.5] : [wobbleScale, wobbleScale, wobbleScale],
    lineSize: hovered ? 100 : 0,
    lineOpacity: hovered ? 1 : 0,
  })

  useFrame(() => {
    setWobbleScale(Math.sin(performance.now() / 200) / 3 + 1.5)
  })

  return (
    <group position={position}>
      <animated.sprite scale={hoverAnimation.scale} cursor="pointer">
        <spriteMaterial attach="material" map={texture} />
      </animated.sprite>
      <Html center>
        <div
          css={css`
            transition: initial;
            height: 20px;
            width: 20px;
            background-color: none;
            cursor: pointer;
          `}
          onClick={() => {
            zoomInAnimation(index)
          }}
          onPointerOver={() => {
            setHovered(true), setPointHovering(true)
          }}
          onPointerOut={() => {
            setHovered(false), setPointHovering(false)
          }}
        ></div>
      </Html>
    </group>
  )
}
