/** @jsx jsx */

import { jsx, css } from "@emotion/react"
import { colors, desktop } from "@src/style"
import { Section, H4, H1, Button, Body } from "@src/components"
import { Column, Row } from "@src/components/grid"
import { graphql } from "gatsby"
import { Fragment, useState } from "react"
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

export function Intro(
  supertitle: any,
  title: any,
  shareButton: any,
  subtitle: any,
  bodyNode: any
) {
  const [showShare, setShowShare] = useState(false)
  return (
    <Fragment>
      <Section
        background="white"
        noReveal
        css={css`
          ${desktop} {
            padding-bottom: 0px;
          }
        `}
      >
        <Row>
          <Column
            css={css`
              ${desktop} {
                flex: 2;
              }
            `}
          >
            {supertitle && (
              <H4
                css={css`
                  font-size: 24px;
                  margin-bottom: 10px;
                  color: ${colors.red};
                  font-weight: 400;
                `}
              >
                {supertitle}
              </H4>
            )}
            <H1>{title}</H1>
          </Column>
          <Column
            className="hide-mobile"
            css={css`
              text-align: right;
            `}
          >
            {shareButton && (
              <Button onClick={() => setShowShare(true)}>Delen</Button>
            )}
          </Column>
        </Row>
        <Row>
          {subtitle && (
            <Column>
              <Body
                css={css`
                  font-weight: 700;
                `}
              >
                {subtitle}
              </Body>
            </Column>
          )}
          <Column
            css={css`
              ${desktop} {
                flex: 2;
              }
            `}
          >
            <Body
              dangerouslySetInnerHTML={{
                __html: bodyNode.childMarkdownRemark.html,
              }}
            />
          </Column>
        </Row>
      </Section>

      {showShare && (
        <Fragment>
          <div
            css={css`
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: rgba(0, 0, 0, 0.3);
              z-index: 1000;
            `}
            onClick={() => setShowShare(false)}
          ></div>
          <Section
            noReveal
            css={css`
              position: fixed;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1001;
            `}
            background="black"
          >
            <H1>Delen</H1>
            <Body>Deel deze pagina met één van de volgende platforms:</Body>
            <div
              css={css`
                margin-bottom: 16px;
                display: flex;
                justify-content: space-between;
              `}
            >
              <EmailShareButton url={window.location.toString()}>
                <EmailIcon />
              </EmailShareButton>
              <FacebookShareButton url={window.location.toString()}>
                <FacebookIcon />
              </FacebookShareButton>
              <TelegramShareButton url={window.location.toString()}>
                <TelegramIcon />
              </TelegramShareButton>
              <TwitterShareButton url={window.location.toString()}>
                <TwitterIcon />
              </TwitterShareButton>
              <WhatsappShareButton url={window.location.toString()}>
                <WhatsappIcon />
              </WhatsappShareButton>
            </div>
            <input
              type="text"
              value={window.location.toString()}
              readOnly
              css={css`
                width: 100%;
              `}
            />
          </Section>
        </Fragment>
      )}
    </Fragment>
  )
}

export const introQuery = graphql`
  fragment IntroFragment on DatoCmsIntro {
    model {
      name
    }
    id
    shareButton
    subtitle
    supertitle
    title
    bodyNode {
      childMarkdownRemark {
        html
      }
    }
  }
`
