/** @jsx jsx */

import { jsx, css } from "@emotion/react"
import BackgroundImage from "gatsby-background-image"
import { colors, desktop, mobile } from "@src/style"
import { graphql, StaticQuery } from "gatsby"
import { magazineListItem } from "@src/pages-base/MagazineBase"
import { Section, H3, CTA, Button, H4, H6 } from "."
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

type Props = {
  locale: "en" | "nl"
  magazine: {
    title: string
    allArticlesLinkText: string
    fixedHeight: boolean
  }
  noReveal?: boolean
}

export const MagazineList: React.FC<Props> = ({
  locale,
  magazine: { title, allArticlesLinkText, fixedHeight },
  noReveal,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query MagazineListQuery {
          datoCmsSiteConfig {
            highlightedMagazineItem {
              slug
              title
              id
              tags
              tagsAuthor
              meta {
                firstPublishedAt
              }
              thumbnail {
                fluid(
                  maxWidth: 1200
                  imgixParams: { fm: "jpg", auto: "compress" }
                ) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
          allDatoCmsMagazineItem(
            filter: { hidden: { ne: true } }
            sort: { fields: meta___firstPublishedAt, order: DESC }
          ) {
            edges {
              node {
                title
                slug
                id
                meta {
                  firstPublishedAt
                }
                thumbnail {
                  fluid(
                    maxWidth: 700
                    imgixParams: { fm: "jpg", auto: "compress" }
                  ) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
                locale
              }
            }
          }
        }
      `}
      render={(data: any) => {
        // console.log({data})
        const { highlightedMagazineItem } = data.datoCmsSiteConfig
        return (
          <Section background="offWhite" noReveal={noReveal}>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 32px;
              `}
            >
              <H3>{title}</H3>
              <CTA
                css={css`
                  ${mobile} {
                    display: none;
                  }
                `}
                href={locale == "nl" ? "/verdieping" : `/${locale}/magazine`}
              >
                {allArticlesLinkText}
              </CTA>
            </div>

            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry gutter={16}>
                {data.allDatoCmsMagazineItem.edges
                  .filter((item: any) => item.node.locale === locale)
                  .sort(({ node }: { node: any }) =>
                    node.id === highlightedMagazineItem.id ? -1 : 1
                  )
                  .slice(0, 3)
                  .map(
                    magazineListItem.bind(this, {
                      locale,
                      fixedHeight,
                      highlightedMagazineItem,
                    })
                  )}
              </Masonry>
            </ResponsiveMasonry>
            <Button
              css={css`
                margin-top: 30px;
                ${desktop} {
                  display: none;
                }
              `}
            >
              <a href={locale == "nl" ? "/verdieping" : `/${locale}/magazine`}>
                {allArticlesLinkText}
              </a>
            </Button>
          </Section>
        )
      }}
    />
  )
}

export const magazineListQuery = graphql`
  fragment MagazineFragment on DatoCmsMagazine {
    model {
      name
    }
    id
    title
    allArticlesLinkText
    fixedHeight
  }
`
