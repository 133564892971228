/** @jsx jsx */

import { jsx, css } from "@emotion/react"
import {
  Button,
  CircledChevron,
  CTA,
  H4,
  H5,
  LinkButton,
} from "@src/components"
import { colors, mobile, xs } from "@src/style"
import { shuffle } from "lodash"
import { Fragment, useState } from "react"
import { IoMdClose } from "react-icons/io"
import { Props } from "../pages/kennis"
import { stdNormalDistribution } from "@src/utils/standard-normal-distribution"
import { shuffleBlack as shuffleImage } from "@src/assets/images"

export const KennisCardsModule: React.FC<Props> = ({ data }) => {
  const maxItems = 12
  const shuffleArticles = () =>
    shuffle(data.allDatoCmsKnowledge.edges)
      .slice(0, maxItems)
      .map(article => ({
        ...article,
        random: [Math.random(), Math.random(), Math.random(), Math.random()],
      }))
  const [articles, setArticles] = useState(shuffleArticles())
  const handleShuffle = () => setArticles(shuffleArticles())
  const [selectedArticleId, setSelectedArticleId] = useState<string | null>(
    null
  )
  const [detailArticles, setDetailArticles] = useState(
    shuffle(data.allDatoCmsKnowledge.edges).map(article => ({
      ...article,
      random: Math.random(),
    }))
  )
  const [detailArticleIndex, setDetailArticleIndex] = useState<number>(0)
  const handleArticleClick = (id: string) => {
    setDetailArticleIndex(0)
    setDetailArticles(
      articles
        .filter(edge => edge.node.originalId === id)
        .map(article => ({ ...article, random: article.random[0] }))
        .concat(
          data.allDatoCmsKnowledge.edges
            .filter(edge => edge.node.originalId !== id)
            .map(article => ({ ...article, random: Math.random() }))
        )
    )
    setSelectedArticleId(id)
  }
  return (
    <Fragment>
      {selectedArticleId === null && (
        <div
          css={css`
            height: 70vh;
            /* min-height: vw; */
            position: relative;
            z-index: 0;
          `}
        >
          {articles.map((article, i) => (
            <div
              key={`article-${i}`}
              css={css`
                width: 193px;
                min-height: 286px;
                border-radius: 16px;
                padding: 38px 26px;

                background-color: ${article.random[0] < 0.33
                  ? colors.white
                  : article.random[0] < 0.66
                  ? colors.black
                  : colors.red};
                color: ${article.random[0] < 0.33
                  ? colors.black
                  : colors.white};
                position: absolute;
                left: ${stdNormalDistribution(article.random[1]) * 300 - 55}%;
                top: ${article.random[2] * 40}%;
                transform: scale(1) rotate(${-30 + article.random[3] * 60}deg);
                box-shadow: -1px 5px 5px 2px rgba(0, 0, 0, 0.15);
                transition: all 0.5s;
                z-index: ${i};
                cursor: pointer;
                &:hover {
                  z-index: ${articles.length};
                  transform: scale(1.1)
                    rotate(${-30 + article.random[3] * 60}deg);
                  box-shadow: -2px 10px 10px 4px rgba(0, 0, 0, 0.15);
                }
                ${mobile} {
                  left: ${stdNormalDistribution(article.random[1]) * 100 - 0}%;
                }
                ${xs} {
                  left: ${stdNormalDistribution(article.random[1]) * 80 - 5}%;
                }
              `}
              onClick={() => handleArticleClick(article.node.originalId)}
            >
              {article.node.bodyNode && (
                <div
                  css={css`
                    font-family: Everett;
                    font-size: 18;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: -0.02em;
                    text-align: left;
                  `}
                  dangerouslySetInnerHTML={{
                    __html: article.node.bodyNode.childMarkdownRemark.html,
                  }}
                ></div>
              )}
              <div
                css={css`
                  font-family: Everett;
                  font-size: 18;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 14px;
                  letter-spacing: -0.02em;
                  text-align: left;
                `}
              >
                <div>Leeftijd: {article.node.age}</div>
                <div>Woonplaats: {article.node.city}</div>
              </div>
            </div>
          ))}
          <div
            css={css`
              position: absolute;
              bottom: 50px;
              left: 0;
              right: 0;
              display: flex;
              justify-content: center;
              z-index: ${articles.length + 1};
            `}
          >
            <div
              onClick={handleShuffle}
              css={css`
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                color: ${colors.black};
                margin-top: 24px;
                background: ${colors.white};
                border-radius: 4px;
                padding: 10px 24px;
                width: 210px;
              `}
            >
              <img
                src={shuffleImage}
                css={css`
                  width: 64px;
                  margin-right: 24px;
                `}
              />
              <CTA direction="none">Shuffle</CTA>
            </div>
          </div>
        </div>
      )}
      {selectedArticleId !== null && (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 120px 16px 150px;
            background-color: ${colors.white};
            position: relative;
            ${mobile} {
              padding: 20px 0 60px;
            }
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 50px;
              right: 50px;
              cursor: pointer;
              ${mobile} {
                top: 16px;
                right: 16px;
              }
            `}
            onClick={() => {
              setSelectedArticleId(null)
            }}
          >
            <H4>
              <IoMdClose />
            </H4>
          </div>
          <div
            css={css`
              flex: 1;
              display: flex;
              justify-content: center;
              ${mobile} {
              }
            `}
          >
            {detailArticleIndex !== null && detailArticleIndex > 0 && (
              <CircledChevron
                direction="left"
                css={css`
                  cursor: pointer;
                  margin: 0;
                `}
                color="red"
                hoverColor="red"
                onClick={() => setDetailArticleIndex(detailArticleIndex - 1)}
              ></CircledChevron>
            )}
          </div>
          {detailArticleIndex !== null && !!detailArticles[detailArticleIndex] && (
            <div
              css={css`
                height: 100%;
                display: flex;
                width: 526px;
                max-width: calc(100% - 80px);
                flex-direction: column;
                padding: 50px;
                ${mobile} {
                  width: 260px;
                  padding: 50px 0px 0px;
                }
              `}
            >
              <div
                css={css`
                  padding: 40px 40px;
                  width: 100%;
                  border-radius: 16px;
                  background-color: ${detailArticles[detailArticleIndex]
                    .random < 0.33
                    ? colors.white
                    : detailArticles[detailArticleIndex].random < 0.66
                    ? colors.black
                    : colors.red};
                  color: ${detailArticles[detailArticleIndex].random < 0.33
                    ? colors.black
                    : colors.white};
                  box-shadow: -1px 5px 5px 2px rgba(0, 0, 0, 0.3);
                  min-height: 614px;
                  transition: all 0.5s;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  ${mobile} {
                    padding: 50px 30px 75px;
                    min-height: 300px;
                  }
                `}
              >
                {detailArticles[detailArticleIndex].node.bodyNode && (
                  <H5
                    dangerouslySetInnerHTML={{
                      __html:
                        detailArticles[detailArticleIndex].node.bodyNode
                          .childMarkdownRemark.html,
                    }}
                  ></H5>
                )}
                <div>
                  <H5>
                    Leeftijd: {detailArticles[detailArticleIndex].node.age}
                  </H5>
                  <H5>
                    Woonplaats: {detailArticles[detailArticleIndex].node.city}
                  </H5>
                </div>
              </div>
            </div>
          )}
          <div
            css={css`
              flex: 1;
              display: flex;
              justify-content: center;
              ${mobile} {
              }
            `}
          >
            {detailArticles.length > detailArticleIndex + 1 && (
              <CircledChevron
                css={css`
                  margin: 0;
                  cursor: pointer;
                `}
                direction="right"
                color="red"
                hoverColor="red"
                onClick={() => setDetailArticleIndex(detailArticleIndex + 1)}
              ></CircledChevron>
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}
