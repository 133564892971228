export const breakpoints = {
  desktop: 900,
  xs: 400,
}

export const desktop = `@media (min-width: ${breakpoints.desktop}px)`
export const mobile = `@media (max-width: ${breakpoints.desktop - 1}px)`
export const xs = `@media (max-width: ${breakpoints.xs}px)`

export const colors: { [key in color]: string } = {
  black: "#000000",
  red: "#EC464D",
  redSemiTransparent: "#EC464D88",
  green: "#1E3E47",
  greenSemiTransparent: "#1E3E4788",
  white: "#ffffff",
  gold: "#CEA85E",
  grey: "#9d9d9d",
  lightGrey: "#d3d3d3",
  blue: "#1A2951",
  brown: "#632C2B",
  offBlack: "#373736",
  oldRose: "#C18A88",
  steelBlue: "#82ABC0",
  offWhite: "#F6F2EE",
}
