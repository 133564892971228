import { css, Global } from "@emotion/react"
import React from "react"
import { desktop, mobile } from "./variables"

export const GlobalStyles = () => (
  <Global
    styles={css`
      * {
        box-sizing: border-box;
        transition: 0.2s;
      }
      html,
      body {
        margin: 0;
        padding: 0;
      }
      body::after {
        display: block;
        content: " ";
        position: fixed;
        z-index: 9999999999;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: white;
      }
      body.wf-snv-cond-d-fixed--loaded.wf-snv-w01-extra-condensed-d--loaded.wf-s-hne-breit-test--loaded.wf-snv-w01-regular-d--loaded.wf-dm-sans--loaded.wf-gt-flexa-trial--loaded.wf-everett-light--loaded.wf-eczar--loaded.wf-everett--loaded::after {
        display: none;
      }
      a {
        color: inherit;
      }
      .hide-mobile {
        ${mobile} {
          display: none !important;
        }
      }
      .hide-desktop {
        ${desktop} {
          display: none !important;
        }
      }

      @keyframes vibrate-1 {
        0% {
          -webkit-transform: translate(0);
          transform: translate(0);
        }
        20% {
          -webkit-transform: translate(-15px, 15px);
          transform: translate(-15px, 15px);
        }
        40% {
          -webkit-transform: translate(-15px, -15px);
          transform: translate(-15px, -15px);
        }
        60% {
          -webkit-transform: translate(15px, 15px);
          transform: translate(15px, 15px);
        }
        80% {
          -webkit-transform: translate(15px, -15px);
          transform: translate(15px, -15px);
        }
        100% {
          -webkit-transform: translate(0);
          transform: translate(0);
        }
      }

      @keyframes sway-parent {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      @keyframes sway-child {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(-360deg);
        }
      }

      /* mailchimp stuff */
      #mc_embed_signup {
        background: #fff;
        clear: left;
        font: Everett;
      }
      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
      #mc-embedded-subscribe-form input[type="checkbox"] {
        display: inline;
        width: auto;
        margin-right: 10px;
      }
      #mergeRow-gdpr {
        margin-top: 20px;
      }
      #mergeRow-gdpr fieldset label {
        font-weight: normal;
      }
      #mc-embedded-subscribe-form .mc_fieldset {
        border: none;
        min-height: 0px;
        padding-bottom: 0px;
      }
    `}
  ></Global>
)
