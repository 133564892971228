/** @jsx jsx */

import { css, jsx } from "@emotion/react"
import { colors, desktop, mobile } from "@src/style"
import { graphql, StaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Body, Column, CTA, H1, H4, H5, H6, Row, Section } from "."
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"

type Props = {
  locale: "en" | "nl"
  events: {
    title: string
    linkText: string
    itemsPerPage: number
    archive: boolean
    bodyNode: {
      childMarkdownRemark: {
        html: string
      }
    }
  }
  noReveal?: boolean
}

export const EventsList: React.FC<Props> = ({ locale, events, noReveal }) => {
  return (
    <StaticQuery
      query={graphql`
        query EventsListQuery {
          allDatoCmsEventModel(sort: { fields: dateDate, order: ASC }) {
            edges {
              node {
                title
                slug
                date
                dateDate
                hidden
                meta {
                  firstPublishedAt
                }
                locale
                positie
              }
            }
          }
        }
      `}
      render={(data: any) => {
        const [selectedPage, setSelectedPage] = useState(0)
        const maxItemsPerPage = events.itemsPerPage
        const [selectedEvents, setSelectedEvents] = useState([])
        const localeEvents = data.allDatoCmsEventModel.edges
          .filter((item: any) => item.node.locale === locale)
          .filter((item: any) => !item.node.hidden)
        const dateEvents = localeEvents.filter((item: any) => {
          return events.archive
            ? new Date(item.node.dateDate) < new Date()
            : new Date(item.node.dateDate) > new Date()
        })

        useEffect(() => {
          setSelectedEvents(
            dateEvents.slice(
              selectedPage * maxItemsPerPage,
              selectedPage * maxItemsPerPage + maxItemsPerPage
            )
          )
        }, [selectedPage])

        return (
          <Section
            background={events.archive ? "red" : "white"}
            noReveal={noReveal}
          >
            <div
              css={css`
                text-align: center;
              `}
            >
              <H1
                css={css`
                  text-align: center;
                `}
              >
                {events.title}
              </H1>
              {events.bodyNode && (
                <Body
                  css={css`
                    text-align: center;
                  `}
                  dangerouslySetInnerHTML={{
                    __html: events.bodyNode.childMarkdownRemark!.html!,
                  }}
                />
              )}
            </div>
            <div>
              {selectedEvents
                .map((item: any) => item.node)
                .map((event: any, i: number) => {
                  if (!event) {
                    return null
                  }
                  return (
                    <a
                      key={`event-${i}`}
                      css={css`
                        text-decoration: none;
                        display: block;
                        margin: 0 -30px;
                        border-bottom: 1px solid black;
                        &:first-of-type {
                          border-top: 1px solid black;
                        }
                        ${desktop} {
                          margin: 0;
                        }
                      `}
                      href={`/${
                        locale === "nl"
                          ? "publieksprogramma/"
                          : `${locale}/public-program/`
                      }${event.slug}`}
                    >
                      <Row
                        css={css`
                          margin: 0 -30px;
                          padding: 35px 50px 25px;
                          ${desktop} {
                            padding: 50px 20px;
                            align-items: center;
                          }
                        `}
                      >
                        <Column>
                          <H5
                            css={css`
                              ${mobile} {
                                text-align: center;
                              }
                              ${desktop} {
                                min-width: 150px;
                              }
                            `}
                          >
                            {new Date(event.dateDate).toLocaleDateString(
                              "NL-nl"
                            )}
                          </H5>
                        </Column>
                        <Column
                          css={css`
                            ${desktop} {
                              flex: 6 !important;
                            }
                          `}
                        >
                          <H4
                            css={css`
                              ${desktop} {
                                margin-left: 2vw;
                                margin-bottom: 0px;
                              }
                              ${mobile} {
                                text-align: center;
                              }
                            `}
                          >
                            {event.title}
                          </H4>
                        </Column>
                        <Column className="hide-mobile">
                          <CTA
                            css={css`
                              color: ${events.archive
                                ? colors.black
                                : colors.red};
                              margin-bottom: 0;
                            `}
                          >
                            {events.linkText}
                          </CTA>
                        </Column>
                      </Row>
                    </a>
                  )
                })}
            </div>
            <div>
              <Row
                css={css`
                  margin: 0 -30px;
                  padding: 35px 50px 25px;
                  justify-content: space-between;
                  font-size: 24px;
                  ${desktop} {
                    padding: 50px 20px;
                    align-items: center;
                  }
                `}
              >
                <span>
                  {selectedPage > 0 && (
                    <div
                      css={css`
                        cursor: pointer;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                      `}
                      onClick={() => setSelectedPage(selectedPage - 1)}
                    >
                      <FiChevronLeft />
                      <H6>
                        {locale == "nl" ? "Vorige Pagina" : "Previous Page"}
                      </H6>
                    </div>
                  )}
                </span>
                <span>
                  {selectedPage + 1 < dateEvents.length / maxItemsPerPage && (
                    <div
                      css={css`
                        cursor: pointer;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                      `}
                      onClick={() => setSelectedPage(selectedPage + 1)}
                    >
                      <H6>
                        {locale == "nl" ? "Volgende Pagina" : "Next Page"}
                      </H6>
                      <FiChevronRight />
                    </div>
                  )}
                </span>
              </Row>
            </div>
          </Section>
        )
      }}
    />
  )
}

export const eventsListQuery = graphql`
  fragment EventsListFragment on DatoCmsEventsList {
    model {
      name
    }
    id
    title
    linkText
    itemsPerPage
    archive
    bodyNode {
      childMarkdownRemark {
        html
      }
    }
  }
`
